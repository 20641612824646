import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Box } from 'theme-ui'
import i18next from 'i18next'
import { UserContext } from '../../../../../providers/UserProvider'
import { loggedIn } from '../../../../../helpers/userHelper'
import { LinkField, TextField } from '../../../../../types/layoutService'
import {
  COLUMN_ID_BRAND,
  COLUMN_ID_PART_NUMBER,
  COLUMN_ID_SUPPLIER_PART_NUMBER,
  DEFAULT_SORT_DIRECTION,
  SORT_DIRECTION_ASCENDING,
  SORT_DIRECTION_DESCENDING,
} from '../../../../../constants/searchConstants'
import {
  getDisplayProperties,
  getNextSortDirection,
  partSorter,
} from '../../../../../helpers/searchTableViewHelper'
import SortableTableColumn from '../../../../molecules/Table/TableSortableColumn'
import {
  Class,
  Property,
} from '../../../../../hooks/services/graphql/useClassInformationListService'
import { PartFulFilled } from '../../../../../hooks/services/rest/ecommerce/useFulfilledPartSearchService'
import { pushToDataLayer } from '../../../../../helpers/analyticsHelper'
import PartSearchTableRow from './PartSearchTableRow'
import { scrollTo } from '../../../../../helpers/dom'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'
import PartLineTitle from '../../../../molecules/PartLine/PartLineTitle'
import TableGridHead from '../../../../molecules/Table/TableGridHead'
import TableGridBody from '../../../../molecules/Table/TableGridBody'
import TableColumnTitle from '../../../../molecules/Table/TableColumnTitle'

export type SortDirection = 'ASC' | 'DESC' | 'UNSET'

interface PartSearchTableProps {
  parts: PartFulFilled[]
  classInformation: Class
  partPage: LinkField
  supersessionText: TextField
}

const getDataLayerEventAction = (
  sortColumnId: string | undefined,
  displayProperties: Property[]
) => {
  if (sortColumnId === COLUMN_ID_PART_NUMBER)
    return i18next.t('partLabels.partNumberAbbreviation')
  if (sortColumnId === COLUMN_ID_SUPPLIER_PART_NUMBER)
    return i18next.t('partLabels.manufacturerPartNumberAbbreviation')

  return displayProperties.find(({ id }) => id.toString() === sortColumnId)
    ?.englishDescription
}

const PartSearchTable: FC<PartSearchTableProps> = ({
  classInformation: { englishDescription: classEnglishDescription, properties },
  parts,
  partPage,
  supersessionText,
}) => {
  const { user } = useContext(UserContext)

  const [sortedParts, setSortedParts] = useState<PartFulFilled[]>()
  const [scrollPosition, setScrollPosition] = useState(0)
  const [sortColumnId, setSortColumnId] = useState<string | undefined>()
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    DEFAULT_SORT_DIRECTION
  )

  const [displayProperties, gridTemplate] = useMemo(() => {
    const newDisplayProperties = getDisplayProperties(properties)

    return [
      newDisplayProperties,
      {
        columnSizes: `0.65fr 1fr 1fr ${newDisplayProperties
          .map(() => '1fr')
          .join(' ')} 1fr`,
        // css prop: grid-template-columns
        columnSizesLoggedIn: `0.65fr 1fr 1fr ${newDisplayProperties
          .map(() => '1fr')
          .join(' ')} 1fr 1fr 2fr`,
        // css prop: grid-template-columns
      },
    ]
  }, [properties])

  const pushDataLayerEvent = useCallback(
    (direction: SortDirection, clickedColumnId: string) => {
      switch (direction) {
        case SORT_DIRECTION_ASCENDING:
          pushToDataLayer({
            [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.TABLE_SORT,
            sorting_method: DATA_LAYER.EVENT_CATEGORY.TABLE_SORT_ASCEND,
            column_name:
              getDataLayerEventAction(clickedColumnId, displayProperties) || '',
            class_name: classEnglishDescription || '',
          })
          break

        case SORT_DIRECTION_DESCENDING:
          pushToDataLayer({
            [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.TABLE_SORT,
            sorting_method: DATA_LAYER.EVENT_CATEGORY.TABLE_SORT_DESCEND,
            column_name:
              getDataLayerEventAction(clickedColumnId, displayProperties) || '',
            class_name: classEnglishDescription || '',
          })
          break

        default:
          pushToDataLayer({
            [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.TABLE_SORT,
            sorting_method: DATA_LAYER.EVENT_CATEGORY.TABLE_SORT_RESET,
            column_name:
              getDataLayerEventAction(clickedColumnId, displayProperties) || '',
            class_name: classEnglishDescription || '',
          })
      }
    },
    [classEnglishDescription, displayProperties]
  )

  const sortParts = useCallback(
    () =>
      partSorter(
        displayProperties,
        [...parts], // spread operator to make a copy of parts and prevent parts from being overwritten
        sortColumnId,
        sortDirection
      ),
    [displayProperties, parts, sortColumnId, sortDirection]
  )

  const sortColumn = useCallback(
    (columnId: string) => {
      setScrollPosition(
        document.documentElement.scrollTop || document.body.scrollTop
      )

      if (!sortColumnId || columnId === sortColumnId) {
        const newSortDirection = getNextSortDirection(sortDirection)

        if (newSortDirection === DEFAULT_SORT_DIRECTION) {
          // reset sorting
          setSortColumnId(undefined)
          setSortDirection(DEFAULT_SORT_DIRECTION)

          pushDataLayerEvent(DEFAULT_SORT_DIRECTION, columnId)
        } else {
          setSortColumnId(columnId)
          setSortDirection(newSortDirection)

          pushDataLayerEvent(newSortDirection, columnId)
        }
      } else if (sortColumnId && columnId !== sortColumnId) {
        // sorting a different column then already sorted
        setSortColumnId(columnId)
        setSortDirection(SORT_DIRECTION_ASCENDING)

        pushDataLayerEvent(SORT_DIRECTION_ASCENDING, columnId)
      } else {
        // reset sorting
        setSortColumnId(undefined)
        setSortDirection(DEFAULT_SORT_DIRECTION)

        pushDataLayerEvent(DEFAULT_SORT_DIRECTION, columnId)
      }
    },
    [pushDataLayerEvent, sortColumnId, sortDirection]
  )

  useEffect(() => {
    if (sortColumnId) {
      setSortedParts(sortParts())
    } else {
      setSortedParts(parts) // set back to original state
    }
  }, [parts, sortColumnId, sortParts])

  useEffect(() => {
    scrollTo({ top: scrollPosition })
  }, [scrollPosition, sortParts])

  if (!sortedParts?.length) return null

  return (
    <Box
      data-t-id="search-result-table"
      sx={{
        mb: 7,
        overflowX: 'auto',
        '> table': {
          minWidth: '1160px',
        },
      }}
    >
      <Box
        as="table"
        sx={{
          display: 'grid',
          gridTemplateColumns: loggedIn(user)
            ? gridTemplate.columnSizesLoggedIn
            : gridTemplate.columnSizes,
        }}
      >
        <TableGridHead>
          <TableColumnTitle>{i18next.t('commonLabels.product')}</TableColumnTitle>

          <SortableTableColumn
            columnId={COLUMN_ID_PART_NUMBER}
            sortColumnId={sortColumnId}
            sortDirection={sortDirection}
            onClick={(id) => sortColumn(id)}
          >
            {i18next.t('partLabels.partNumberAbbreviation')}
          </SortableTableColumn>

          <SortableTableColumn
            columnId={COLUMN_ID_SUPPLIER_PART_NUMBER}
            sortColumnId={sortColumnId}
            sortDirection={sortDirection}
            onClick={(id) => sortColumn(id)}
          >
            {i18next.t('partLabels.manufacturerPartNumberAbbreviation')}
          </SortableTableColumn>

          {!!displayProperties?.length &&
            displayProperties.map(({ id: propertyId, description }, i) => (
              <SortableTableColumn
                key={i.toString()}
                columnId={propertyId.toString()}
                sortColumnId={sortColumnId}
                sortDirection={sortDirection}
                onClick={(id) => sortColumn(id)}
              >
                {description}
              </SortableTableColumn>
            ))}

          <SortableTableColumn
            columnId={COLUMN_ID_BRAND}
            sortColumnId={sortColumnId}
            sortDirection={sortDirection}
            onClick={(id) => sortColumn(id)}
          >
            {i18next.t('commonLabels.brand')}
          </SortableTableColumn>

          {loggedIn(user) && (
            <PartLineTitle>{i18next.t('stockLabels.availability')}</PartLineTitle>
          )}

          {loggedIn(user) && (
            <PartLineTitle>{i18next.t('partSalesLabels.price')}</PartLineTitle>
          )}
        </TableGridHead>

        <TableGridBody>
          {sortedParts.map((partFulfilled, i) => (
            <PartSearchTableRow
              key={i.toString()}
              partFulfilled={partFulfilled}
              displayProperties={displayProperties}
              partPage={partPage}
              position={i}
              supersessionText={supersessionText}
            />
          ))}
        </TableGridBody>
      </Box>
    </Box>
  )
}

export default PartSearchTable
