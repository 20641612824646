import React, { ReactNode } from 'react'
import { DEFAULT_SORT_DIRECTION } from '../../../constants/searchConstants'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import SortDirectionIcons from '../../atoms/SortDirectionIcons/SortDirectionIcons'
import { SortDirection } from '../../organisms/SearchResults/PartSearch/Table/PartSearchTable'
import TableColumnTitle from './TableColumnTitle'
import { Flex } from 'theme-ui'

interface SortableTableColumnProps {
  columnId: string
  sortColumnId?: string
  sortDirection: SortDirection
  onClick: (id: string) => void
  children: ReactNode
}

const SortableTableColumn = ({
  columnId,
  sortColumnId,
  sortDirection,
  onClick,
  children,
}: SortableTableColumnProps) => {
  return (
    <TableColumnTitle>
      <ClickableWrapper
        onClick={() => onClick(columnId)}
        sx={{
          color: columnId === sortColumnId ? 'primary' : 'gray.3',
          inlineSize: '100%',
          ':hover': {
            color: 'primary',
            '::after': {
              backgroundColor: 'primary',
            },
          },
        }}
      >
        <Flex sx={{ flexWrap: 'nowrap', alignItems: 'center' }}>
          {children}

          <SortDirectionIcons
            sortDirection={
              columnId === sortColumnId ? sortDirection : DEFAULT_SORT_DIRECTION
            }
            sx={{
              mt: 1,
              ml: 2,
              flexShrink: 0,
            }}
          />
        </Flex>
      </ClickableWrapper>
    </TableColumnTitle>
  )
}

export default SortableTableColumn
