import React, { useCallback } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import { useHistory } from 'react-router-dom'
import {
  URL_FILTER_PARAMETER,
  URL_SEARCH,
  URL_SORT,
} from '../../../../constants/urlConstants'
import Button from '../../../atoms/Button/Button'
import MyFleetListTableRow from './MyFleetListTableRow'
import {
  Filter,
  Vehicle,
} from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import { LinkField } from '../../../../types/layoutService'
import TableColumnTitle from '../../../molecules/Table/TableColumnTitle'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../../constants/iconConstants'
import { COLORS } from '../../../../constants/themeConstants'
import { MULTISUPPORT_SITE_CONTEXT } from '../../../../constants/environmentConstants'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import TableGridHead from '../../../molecules/Table/TableGridHead'
import TableGridBody from '../../../molecules/Table/TableGridBody'

interface MyFleetListTableProps {
  vehicleData: Vehicle[]
  unfilteredVehicleData: Vehicle[]
  filterGroups?: Filter[]
  vehicleDetailLink?: LinkField
  requestQuoteMenuOptionTitle: string | undefined
  requestMaintenanceAppointmentOptionTitle: string | undefined
  deleteVehicleOptionTitle: string | undefined
}

const MyFleetListTable = ({
  vehicleData,
  unfilteredVehicleData,
  filterGroups,
  vehicleDetailLink,
  requestQuoteMenuOptionTitle,
  requestMaintenanceAppointmentOptionTitle,
  deleteVehicleOptionTitle,
}: MyFleetListTableProps) => {
  const {
    site: { name },
  } = useSitecoreContext()
  const history = useHistory()
  const handleClick = useCallback(() => {
    const params = new URLSearchParams(history.location.search)
    params.delete(URL_SORT)
    params.delete(URL_SEARCH)
    params.delete(URL_FILTER_PARAMETER)

    history.push({
      search: params.toString(),
    })
  }, [history])

  const columnSizes =
    name === MULTISUPPORT_SITE_CONTEXT
      ? '5px 100px 4fr 1.5fr 48px 1fr 4fr 2fr 2fr 2fr 2fr'
      : '5px 100px 5fr 2fr 48px 4fr 4fr 2fr'
  return (
    <>
      <Box
        as="table"
        sx={{
          display: 'grid',
          gridTemplateColumns: columnSizes,
          columnGap: 3,
        }}
      >
        <TableGridHead>
          <TableColumnTitle>
            <Box variant="visuallyHidden">Status</Box>
          </TableColumnTitle>
          <TableColumnTitle>
            <Box variant="visuallyHidden">Image</Box>
          </TableColumnTitle>
          <TableColumnTitle>{i18next.t('myFleet.vehicleTitle')}</TableColumnTitle>
          <TableColumnTitle>{i18next.t('myFleet.mileageTitle')}</TableColumnTitle>
          <TableColumnTitle>
            <IconWrapper icon={ICONS.CONNECTED} size={4} color={COLORS.BLACK} />
          </TableColumnTitle>
          <TableColumnTitle>{i18next.t('myFleet.servicesTitle')}</TableColumnTitle>
          {name === MULTISUPPORT_SITE_CONTEXT && (
            <TableColumnTitle>
              {i18next.t('myFleet.mainServicePartnerTitle')}
            </TableColumnTitle>
          )}
          <TableColumnTitle>
            {i18next.t('myFleet.maintenanceTitle')}
          </TableColumnTitle>
          {name === MULTISUPPORT_SITE_CONTEXT && (
            <TableColumnTitle>
              {i18next.t('myFleet.servicePlanTitle')}
            </TableColumnTitle>
          )}
          {name === MULTISUPPORT_SITE_CONTEXT && (
            <TableColumnTitle>{i18next.t('myFleet.invoiceTitle')}</TableColumnTitle>
          )}
          <TableColumnTitle textAlign="center">
            {i18next.t('myFleet.actionTitle')}
          </TableColumnTitle>
        </TableGridHead>

        <TableGridBody>
          {vehicleData.map((vehicle: Vehicle) => (
            <MyFleetListTableRow
              key={vehicle.chassisNumber}
              vehicle={vehicle}
              filterGroups={filterGroups}
              vehicleDetailLink={vehicleDetailLink}
              requestQuoteMenuOptionTitle={requestQuoteMenuOptionTitle}
              requestMaintenanceAppointmentOptionTitle={
                requestMaintenanceAppointmentOptionTitle
              }
              deleteVehicleOptionTitle={deleteVehicleOptionTitle}
            />
          ))}
        </TableGridBody>
      </Box>

      {!vehicleData.length && unfilteredVehicleData.length > 0 && (
        <Flex
          sx={{
            marginTop: 8,
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Text variant="heading2">{i18next.t('commonLabels.noResultsFound')}</Text>
          <Text variant="bodyContent" color="gray.2" sx={{ marginTop: 3 }}>
            {i18next.t('filteringLabels.noResultForCombinationOfFilters')}
          </Text>
          <Flex sx={{ justifyContent: 'center', marginTop: 4 }}>
            <Button variant="primary" onClick={() => handleClick()}>
              {i18next.t('filteringLabels.resetFilters')}
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  )
}
export default MyFleetListTable
