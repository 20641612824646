import React, { useMemo } from 'react'
import { Box, Flex } from 'theme-ui'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'
import { CommerceOrderItem } from '../../../../../types/commerceApi'
import PromotionSticker from '../../../../atoms/PromotionSticker/PromotionSticker'
import PartLineAnchor from '../../../../molecules/PartLine/PartLineAnchor'
import PartLineBrand from '../../../../molecules/PartLine/PartLineBrand'
import PartLineImage from '../../../../molecules/PartLine/PartLineImage'
import PartLineStockInformation from '../../../../molecules/PartLine/PartLineStockInformation'
import PartPrice from '../../../../molecules/PartPrice/PartPrice'
import { getPartSuggestionHighlighColor } from '../../../../../helpers/partSuggestionHelper'
import PartLineAddToBasket from '../../../../molecules/PartLine/PartLineAddToBasket'
import PartListLineModifiers from '../../../../molecules/PartLine/PartListLineModifiers'
import PartPromotions from '../../../../molecules/PartPromotions/PartPromotions'
import PartLineSuggestionInformation from '../../../../molecules/PartLine/PartLineSuggestionInformation'
import PartLineBasicInformation from '../../../../molecules/PartLine/PartLineBasicInformation'

interface PartListItemProps {
  href: string
  part: CommerceOrderItem
  index: number
  onClick: () => void
}

const PartListItem = ({ href, part, index, onClick }: PartListItemProps) => {
  const color = useMemo(
    () => getPartSuggestionHighlighColor(part) || undefined,
    [part]
  )
  return (
    <Box
      as="tr"
      data-t-id={`shopping-basket-part-line-${part?.partNumber}`}
      sx={{
        gridColumn: '1 / -1',
        display: 'grid',
        gridTemplateColumns: 'subgrid',
        gridTemplateAreas: '"info brand stock unit basket total modifiers"',
        alignItems: 'center',
        position: 'relative',
        paddingInline: 4,
        paddingBlock: 3,
        borderBlockEnd: '1px solid',
        borderColor: 'gray.1',
        '&::before': {
          position: 'absolute',
          content: '""',
          width: '4px',
          left: ['-16px', '-16px', 0],
          height: 'calc(100% + 1px)',
          backgroundColor: color,
        },

        '> *': {
          gridRow: '1 / 2',
        },
      }}
    >
      <Box
        as="td"
        sx={{
          gridColumn: '1 / 3',
          display: 'grid',
          gridTemplateColumns: 'subgrid',
          position: 'relative',
        }}
      >
        <Flex sx={{ gap: 6 }}>
          <PromotionSticker
            price={part.price}
            variant="s"
            sx={{
              width: 64,
              display: 'inline-block',
            }}
          >
            <PartLineImage
              src={part?.images?.[0]?.thumbnailImageUrl}
              alt={part?.description}
            />
          </PromotionSticker>

          <div>
            {part?.suggestionInformation && (
              <PartLineSuggestionInformation part={part} />
            )}
            <PartLineAnchor
              href={href}
              onClick={onClick}
              sx={{
                '&:hover, &:active, &:focus': {
                  textDecoration: 'underline',
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  insetInline: 0,
                  insetBlock: '-17px',
                },
              }}
            >
              <PartLineBasicInformation
                description={part?.description}
                partNumber={part?.partNumber}
                displayPartNumber={part?.displayPartNumber}
                supplierPartNumber={part?.supplierPartNumber}
              />
            </PartLineAnchor>
          </div>
        </Flex>
      </Box>

      <Box as="td" sx={{ gridArea: 'brand' }}>
        <PartLineBrand brand={part?.brand} />
      </Box>

      <Box as="td" sx={{ gridArea: 'stock' }}>
        <PartLineStockInformation
          partNumber={part.type === 'Paccar' ? part.partNumber : undefined}
          displayPartNumber={
            part.type === 'Paccar' ? part.displayPartNumber : undefined
          }
          stockInformation={part.stock}
          categoryId={part.category?.mainCategoryId}
          subcategoryId={part.category?.subCategoryId}
          componentOrigin={DATA_LAYER.COMPONENT_ORIGIN.SHOPPING_BASKET}
        />
      </Box>

      <Box as="td" sx={{ gridArea: 'unit' }}>
        <PartPrice price={part.price} />
        <PartPromotions price={part.price} sx={{ marginBlockStart: 1 }} />
      </Box>
      <Box as="td" sx={{ gridArea: 'basket' }}>
        <PartLineAddToBasket part={part} />
      </Box>
      <Box
        as="td"
        sx={{
          gridArea: 'total',
          justifySelf: 'end',
        }}
      >
        <PartPrice price={part.price} useTotalPrice />
      </Box>

      <Box as="td" sx={{ gridArea: 'modifiers', justifySelf: 'end' }}>
        <PartListLineModifiers
          part={part}
          index={index}
          sx={{
            display: 'flex',
            gap: 2,
          }}
        />
      </Box>
    </Box>
  )
}

export default PartListItem
