import React, { useContext } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import i18next from 'i18next'
import Container from '../../atoms/Container/Container'
import { COLORS } from '../../../constants/themeConstants'
import useAxiosClient from '../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../providers/AxiosClientProvider'
import { UserContext } from '../../../providers/UserProvider'
import fetchDafCompanyVehicles from '../../../services/rest/ecommerce/dafCompanyVehicles'
import MaintenanceStateChart from '../../molecules/ActionsStatistics/ActionsStatisticsCharts/MaintenanceStateChart'
import Spinner from '../../atoms/Spinner/Spinner'
import MultisupportUrgentTasks from '../MultisupportUrgentTasks/MultisupportUrgentTasks'
import PlansAndOtherServices from '../../molecules/PlansAndOtherServices/PlansAndOtherServices'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../types/userProps'
import BannerGeneric from '../../atoms/Banner/BannerGeneric'
import { ICONS } from '../../../constants/iconConstants'
import { Datasource, LinkField, TextField } from '../../../types/layoutService'
import withData from '../../../enhancers/withData'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { QUERY_KEY_DAF_COMPANY_VEHICLES } from '../../../constants/queryKeyConstants'

interface MyFleetDashboardDatasourceProps {
  title: TextField
  description: TextField
  callToAction: LinkField
}

const MyFleetDashboard = ({
  datasource: { title, description, callToAction },
}: Datasource<MyFleetDashboardDatasourceProps>) => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)

  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const getDafCompanyVehicles = async () => {
    const { token } = axios.CancelToken.source()
    return fetchDafCompanyVehicles(
      client,
      dafVehicleDetailQueryParams,
      token,
      user
    ).then((response) => response.data)
  }
  const { isLoading, data: dafCompanyVehiclesData } = useQuery({
    queryKey: [QUERY_KEY_DAF_COMPANY_VEHICLES],
    queryFn: getDafCompanyVehicles,
    enabled: !!actingCompanyId && !!actingSupplierId,
  })

  const dafCompanyVehicles = dafCompanyVehiclesData?.result?.vehicles

  const numberOfVehicles =
    dafCompanyVehicles?.filter((result) => !result?.noServices).length || 0

  const hasNonMultiSupportServices = dafCompanyVehicles?.filter(
    (result) => result.nonMultiSupportServices?.length > 0
  )

  if (!dafCompanyVehicles || !hasNonMultiSupportServices) {
    return null
  }

  return (
    <Container>
      <Box
        sx={{
          marginTop: [0, 0, 0, '-150px'],
          marginBottom: [4, 4, 4, 7],
        }}
      >
        <Box
          sx={{
            marginTop: 4,
            display: 'grid',
            gridTemplateColumns: [
              'repeat(1, 1fr)',
              'repeat(1, 1fr)',
              'repeat(3, 1fr)',
            ],
            gap: 4,
          }}
        >
          <Flex
            data-t-id="Homepage-ActionsWidget"
            sx={{
              flexDirection: 'column',
              backgroundColor: COLORS.WHITE,
              position: 'relative',
              borderRadius: 8,
              border: '1px solid',
              borderColor: COLORS.MEDIUM_GRAY,
              padding: [3, 3, 3, 5],
            }}
          >
            <Box>
              <Text as="h3" variant="heading2">
                {i18next.t('actionsStatisticsLabels.actionsLabel')}
              </Text>
              <Text as="span" variant="bodySmall" mb={2}>
                {i18next.t('actionsStatisticsLabels.actionsSubtitleLabel')}
              </Text>
            </Box>

            {isLoading && !dafCompanyVehiclesData && (
              <Box
                sx={{
                  paddingY: 5,
                  width: '100%',
                  height: '100%',
                }}
              >
                <Spinner />
              </Box>
            )}

            {!isLoading && dafCompanyVehiclesData && (
              <Box sx={{ width: ['100%', '100%', '180px'], alignSelf: 'center' }}>
                <Box sx={{ width: ['320px', '320px', '100%'] }}>
                  <MaintenanceStateChart
                    numberOfVehicles={numberOfVehicles}
                    filteredActiveRMContracts={dafCompanyVehicles}
                  />
                </Box>
              </Box>
            )}
          </Flex>

          {!isLoading && dafCompanyVehiclesData && (
            <PlansAndOtherServices
              isFetching={isLoading}
              dafCompanyVehicles={dafCompanyVehiclesData}
            />
          )}

          {!isLoading && dafCompanyVehiclesData && (
            <MultisupportUrgentTasks
              isFetching={isLoading}
              vehicleData={dafCompanyVehiclesData}
            />
          )}
        </Box>
      </Box>

      {!dafCompanyVehiclesData?.result.vehicles.length && (
        <BannerGeneric
          heading={getTextFieldValue(title)}
          cta={{
            icon: ICONS.PLUS,
            children: callToAction?.text,
            as: 'a',
            href: callToAction?.url,
          }}
        >
          <p>{getTextFieldValue(description)}</p>
        </BannerGeneric>
      )}
    </Container>
  )
}

export default withData(MyFleetDashboard)
