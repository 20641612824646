import React from 'react'
import { Box, BoxProps } from 'theme-ui'
import TableGridRow from './TableGridRow'

interface TableHeadProps extends Pick<BoxProps, Exclude<keyof BoxProps, 'css'>> {
  hasBorderInline?: boolean
}

const TableHead = ({ children, hasBorderInline }: TableHeadProps) => (
  <Box
    as="thead"
    sx={{
      gridColumn: '1 / -1',
      display: 'grid',
      gridTemplateColumns: 'subgrid',
      borderBlockStart: '1px solid',
      borderColor: 'gray.1',
      paddingInline: hasBorderInline ? 4 : null,
    }}
  >
    <TableGridRow>{children}</TableGridRow>
  </Box>
)

export default TableHead
