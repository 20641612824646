import React, { FC, useMemo } from 'react'
import { Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import { LinkField } from '../../../../../types/layoutService'
import { AssemblyFulfilled } from '../../../../../hooks/services/rest/ecommerce/useAssemblySearchService'
import { resolvePrimaryImage } from '../../../../../helpers/commerceApiHelpers'
import PartLineImage from '../../../../molecules/PartLine/PartLineImage'
import PartLineAction from '../../../../molecules/PartLine/PartLineAnchor'
import PartLineLabelValue from '../../../../molecules/PartLine/PartLineLabelValue'
import TableGridRow from '../../../../molecules/Table/TableGridRow'

interface AssemblySearchLineProps {
  searchAssembly: AssemblyFulfilled['searchAssembly']
  assembly?: AssemblyFulfilled['assembly']
  uri?: AssemblyFulfilled['uri']
  assemblyPage: LinkField
}

const AssemblySearchLine: FC<AssemblySearchLineProps> = ({
  searchAssembly,
  assembly,
  uri,
  assemblyPage,
}) => {
  const href = `${assemblyPage?.url}/${uri}`
  const assemblyBrand = assembly?.brand
  const assemblyPrimaryImage = useMemo(
    () => assembly && resolvePrimaryImage(assembly),
    [assembly]
  )

  return (
    <TableGridRow
      data-t-id={`assembly-search-line-assembly-id-${searchAssembly.bomId}`}
      sx={{ borderBlockEnd: '1px solid', borderColor: 'gray.1' }}
    >
      <td>
        <Flex
          sx={{
            gap: 4,
          }}
        >
          <PartLineImage
            src={assemblyPrimaryImage?.thumbnailImageUrl}
            alt={searchAssembly.bomDescription}
          />

          <PartLineAction
            href={href}
            data-t-id={`assembly-search-line-assembly-link-id-${searchAssembly.bomId}`}
          >
            <Text as="h4" variant="heading4" color="gray.3">
              {searchAssembly.bomDescription}
            </Text>

            <PartLineLabelValue label={i18next.t('partLabels.referenceNr')}>
              {searchAssembly.bomId}
            </PartLineLabelValue>
          </PartLineAction>
        </Flex>
      </td>
      <td>
        <PartLineImage
          src={assemblyBrand?.image?.thumbnailImageUrl}
          alt={assemblyBrand?.description}
          title={assemblyBrand?.description}
        />
      </td>
    </TableGridRow>
  )
}

export default AssemblySearchLine
