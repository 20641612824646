import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import { Vehicle } from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import IconWrapper from '../../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../../constants/iconConstants'
import { COLORS } from '../../../../constants/themeConstants'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import {
  getVehicleLabelForOverview,
  showDashWhenEmptyValue,
  validateAndGetDate,
} from '../../../../helpers/vehicleHelper'
import DefinitionList from '../../../atoms/DefinitionList/DefinitionList'

interface MyFleetVehicleDetailsProps {
  vehicle: Vehicle
}
const MyFleetListVehicleDetails = ({ vehicle }: MyFleetVehicleDetailsProps) => {
  const [breakpointS] = useBreakpoints('s')
  return (
    <>
      <Flex>
        <Box>
          <Text
            as="div"
            sx={{
              marginBlockEnd: 3,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 'bold',
            }}
          >
            {getVehicleLabelForOverview(vehicle)}
          </Text>
        </Box>
        {!breakpointS && (
          <Box sx={{ marginTop: 1, marginLeft: 1 }}>
            <IconWrapper
              icon={ICONS.CONNECTED}
              size={3}
              color={vehicle?.hasDafConnect ? COLORS.BLACK : COLORS.MEDIUM_GRAY}
            />
          </Box>
        )}
      </Flex>

      <DefinitionList
        sx={{
          display: 'grid',
          gridTemplateColumns: '3fr 7fr',
          gap: 2,
        }}
        definitions={[
          {
            term: `${i18next.t('myFleet.typeTitle')}:`,
            detail: showDashWhenEmptyValue(vehicle?.vehicleType).toString(),
          },
          {
            term: `${i18next.t('myFleet.vinTitle')}:`,
            detail: showDashWhenEmptyValue(vehicle?.vin).toString(),
          },
          {
            term: `${i18next.t('myFleet.firstRegistrationTitleAbbr')}:`,
            detail: validateAndGetDate(vehicle.firstRegistrationDate),
          },
        ]}
      />
    </>
  )
}

export default MyFleetListVehicleDetails
