import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import useAxiosClient from '../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from './AxiosClientProvider'
import { UserContext } from './UserProvider'
import { ActiveStoreProviderContext } from './ActiveStoreProvider'
import { DafDefaultQueryParams } from '../types/userProps'
import {
  getRewardsInformation,
  Reward,
  RewardsProps,
} from '../services/rest/ecommerce/loyalty/rewards'
import { ActiveSearchResultFilters } from '../types/filterProps'
import { useHistory, useLocation } from 'react-router-dom'
import { URL_FILTER_PARAMETER, URL_PAGE_PARAM } from '../constants/urlConstants'
import { mapFilterStringToActiveSearchResultFilterObject } from '../helpers/filterHelper'
import { getUrlQueryValue } from '../helpers/urlHelper'
import { REWARDS_PER_PAGE } from '../constants/loyaltyConstants'

interface RewardsContextProps {
  rewardsData: RewardsProps | undefined
  activeFilters: any
  isPending?: boolean
  rewardSidePanelOpen: boolean
  setRewardSidePanelOpen: React.Dispatch<React.SetStateAction<boolean>>
  rewardSidePanelReward: Reward | undefined
  setRewardSidePanelReward: React.Dispatch<React.SetStateAction<Reward | undefined>>
  rewardSidePanelQuantity: number
  setRewardSidePanelQuantity: React.Dispatch<React.SetStateAction<number>>
}
const RewardsContext = createContext<RewardsContextProps>({
  rewardsData: undefined,
  activeFilters: null,
  isPending: false,
  rewardSidePanelOpen: false,
  setRewardSidePanelOpen: () => null,
  rewardSidePanelReward: undefined,
  setRewardSidePanelReward: () => null,
  rewardSidePanelQuantity: 1,
  setRewardSidePanelQuantity: () => null,
})

interface RewardsProviderProps {
  children: React.ReactNode
}

const RewardsProvider = ({ children }: RewardsProviderProps) => {
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const { search } = useLocation()
  const history = useHistory()
  const urlPageNumber = getUrlQueryValue(URL_PAGE_PARAM, history.location.search)
  const [filterValue, setFilterValue] = useState<ActiveSearchResultFilters[] | null>(
    []
  )
  const [rewardSidePanelOpen, setRewardSidePanelOpen] = useState(false)
  const [rewardSidePanelReward, setRewardSidePanelReward] = useState<
    Reward | undefined
  >(undefined)
  const [rewardSidePanelQuantity, setRewardSidePanelQuantity] = useState<number>(1)

  const urlQueryString = useMemo(() => new URLSearchParams(search), [search])

  const urlFilterString: any = useMemo(
    () => urlQueryString.get(URL_FILTER_PARAMETER) || null,
    [urlQueryString]
  )

  const [urlFilterObject] = useMemo(() => {
    const newUrlFilterObject =
      mapFilterStringToActiveSearchResultFilterObject(urlFilterString) || null
    return [newUrlFilterObject]
  }, [urlFilterString])

  useEffect(() => {
    setFilterValue([urlFilterObject])
  }, [urlFilterString, urlFilterObject])

  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const queryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const getRewards = async () => {
    const { token } = axios.CancelToken.source()
    const response = await getRewardsInformation(
      {
        paging: {
          pageNumber: urlPageNumber ? parseInt(urlPageNumber, 10) : 1,
          partsPerPage: REWARDS_PER_PAGE,
        },
        ...mapFilterValues(filterValue),
      },
      queryParams,
      client,
      token,
      user
    )
    return response.data
  }

  useEffect(() => {
    if (
      !rewardSidePanelOpen &&
      queryParams?.customerCompanyId &&
      queryParams?.supplierCompanyId
    ) {
      refetch()
    }
  }, [rewardSidePanelOpen])

  const {
    refetch,
    isPending,
    data: rewardsData,
  } = useQuery({
    queryKey: ['getRewardsInformation'],
    queryFn: getRewards,
    // enabled: !!actingSupplierId && !!actingCompanyId,
    enabled: false,
  })

  const mapFilterValues = (filterValue: any) => {
    if (!filterValue || !filterValue[0]?.category) {
      return null
    }

    const filters = filterValue[0].category.map((category: string) => ({
      id: category,
      values: [{ value: 'true' }],
    }))

    return { filters }
  }

  useEffect(() => {
    if (queryParams?.customerCompanyId && queryParams?.supplierCompanyId) refetch()
  }, [filterValue, queryParams])

  return (
    <RewardsContext.Provider
      value={{
        rewardsData: rewardsData?.result,
        activeFilters: filterValue,
        isPending,
        rewardSidePanelOpen,
        setRewardSidePanelOpen,
        rewardSidePanelReward,
        setRewardSidePanelReward,
        rewardSidePanelQuantity,
        setRewardSidePanelQuantity,
      }}
    >
      {children}
    </RewardsContext.Provider>
  )
}

export { RewardsContext, RewardsProvider }
