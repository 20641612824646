import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../reducers'
import { Box, Flex, Text } from 'theme-ui'
import i18next from 'i18next'
import { getEditableTextFieldValue } from '../../../../../helpers/layoutServiceHelper'
import { useShoppingBasket } from '../../../../../providers/ShoppingBasketProvider'
import PartLineImage from '../../../../molecules/PartLine/PartLineImage'
import PromotionSticker from '../../../../atoms/PromotionSticker/PromotionSticker'
import PartLineSuggestionInformation from '../../../../molecules/PartLine/PartLineSuggestionInformation'
import PartLineAnchor from '../../../../molecules/PartLine/PartLineAnchor'
import PartLineBasicInformation from '../../../../molecules/PartLine/PartLineBasicInformation'
import PartLineBrand from '../../../../molecules/PartLine/PartLineBrand'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'
import PartLineStockInformation from '../../../../molecules/PartLine/PartLineStockInformation'
import PartPrice from '../../../../molecules/PartPrice/PartPrice'
import PartPromotions from '../../../../molecules/PartPromotions/PartPromotions'
import PartLineAddToBasket from '../../../../molecules/PartLine/PartLineAddToBasket'
import PartListLineModifiers from '../../../../molecules/PartLine/PartListLineModifiers'
import { CommerceOrderItem } from '../../../../../types/commerceApi'
import ListBase from '../../../../atoms/ListBase/ListBase'
import Flow from '../../../../atoms/Flow/Flow'

interface PartListCompactProps {
  measureClick: (part: CommerceOrderItem, index: number) => void
  getHref: (part: CommerceOrderItem) => string
}

const PartListCompact = ({ measureClick, getHref }: PartListCompactProps) => {
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const { datasource } = useShoppingBasket()

  return (
    <>
      {order?.items?.length ? (
        <ListBase
          sx={{
            borderBlockStart: '1px solid',
            borderColor: 'gray.1',
          }}
        >
          {order.items.map((part, index) => (
            <Box
              as="li"
              sx={{
                borderBlockEnd: '1px solid',
                borderColor: 'gray.1',
                paddingBlock: 3,
              }}
            >
              <article>
                <Flow>
                  <Box
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <Flow>
                      <PromotionSticker
                        price={part.price}
                        variant="s"
                        sx={{
                          width: 64,
                          display: 'inline-block',
                        }}
                      >
                        <PartLineImage
                          src={part?.images?.[0]?.thumbnailImageUrl}
                          alt={part?.description}
                        />
                      </PromotionSticker>

                      {part?.suggestionInformation && (
                        <div>
                          <PartLineSuggestionInformation part={part} />
                        </div>
                      )}

                      <div>
                        <PartLineAnchor
                          href={getHref(part)}
                          onClick={() => measureClick(part, index)}
                          sx={{
                            '&:hover, &:active, &:focus': {
                              textDecoration: 'underline',
                            },
                            '&::after': {
                              content: '""',
                              position: 'absolute',
                              inset: 0,
                            },
                          }}
                        >
                          <PartLineBasicInformation
                            description={part?.description}
                            partNumber={part?.partNumber}
                            displayPartNumber={part?.displayPartNumber}
                            supplierPartNumber={part?.supplierPartNumber}
                          />
                        </PartLineAnchor>
                      </div>
                    </Flow>
                  </Box>

                  <PartLineStockInformation
                    partNumber={part.type === 'Paccar' ? part.partNumber : undefined}
                    displayPartNumber={
                      part.type === 'Paccar' ? part.displayPartNumber : undefined
                    }
                    stockInformation={part.stock}
                    categoryId={part.category?.mainCategoryId}
                    subcategoryId={part.category?.subCategoryId}
                    componentOrigin={DATA_LAYER.COMPONENT_ORIGIN.SHOPPING_BASKET}
                  />

                  <Flex
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBlockEnd: 3,
                    }}
                  >
                    <div>
                      <PartPrice price={part.price} />
                      <PartPromotions price={part.price} />
                    </div>

                    <PartLineAddToBasket part={part} />

                    <PartPrice price={part.price} useTotalPrice />
                  </Flex>

                  <Flex sx={{ justifyContent: 'space-between' }}>
                    <PartLineBrand brand={part?.brand} />

                    <PartListLineModifiers
                      part={part}
                      index={index}
                      sx={{
                        display: 'flex',
                        gap: 2,
                      }}
                    />
                  </Flex>
                </Flow>
              </article>
            </Box>
          ))}
        </ListBase>
      ) : (
        <>
          <Box
            sx={{
              textAlign: 'center',
              padding: 4,
              border: '1px solid',
              borderColor: 'gray.1',
            }}
          >
            <Text
              as="h2"
              variant="heading3"
              sx={{
                marginBlockEnd: 2,
              }}
            >
              {i18next.t('shoppingLabels.noProducts')}
            </Text>
            <Text
              as="p"
              variant="bodySmall"
              sx={{
                margin: 'auto',
                maxWidth: '85ch',
              }}
            >
              {getEditableTextFieldValue(datasource.emptyOverviewMessage)}
            </Text>
          </Box>
        </>
      )}
    </>
  )
}

export default PartListCompact
