import i18next from 'i18next'
import React, { FC, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { overwriteOrderRecipients } from '../../../../../../actions/shoppingBasket/wizard'
import { RootState } from '../../../../../../reducers'
import { CommerceRecipient } from '../../../../../../types/commerceApi'
import OrderFieldRecipients from './OrderFieldRecipients'

interface OrderRecipientsProps {}

export type MutationType = 'add' | 'delete'
export type Mutation = CommerceRecipient & { mutationType: MutationType }

const addOrRemoveRecipientFromRecipientList = (
  currentList: CommerceRecipient[],
  mutationList: Mutation[]
) => {
  if (!currentList) return []
  if (!mutationList) return currentList

  let newList = currentList

  mutationList.forEach(({ mutationType, ...mutatedRecipient }) => {
    if (mutationType === 'add') {
      newList.push(mutatedRecipient)
    } else {
      newList = newList.filter(
        ({ emailAddress, emailField }) =>
          !(
            emailAddress === mutatedRecipient.emailAddress &&
            emailField === mutatedRecipient.emailField
          )
      )
    }
  })

  return newList
}

const OrderRecipients: FC<OrderRecipientsProps> = () => {
  const dispatch = useDispatch()
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)

  const [, setMutationCount] = useState<number>(0)
  const [, setMutationList] = useState<Mutation[]>([])

  const onChange = useCallback(() => {
    setMutationCount((current) => current + 1)
  }, [dispatch])

  const updateOrderRecipientsStore = useCallback(
    (mutationList: Mutation[]) => {
      const newOrderRecipientsList = addOrRemoveRecipientFromRecipientList(
        order?.recipients || [],
        mutationList
      )

      dispatch(overwriteOrderRecipients(newOrderRecipientsList))
    },
    [dispatch, order]
  )

  const onChangeEnd = useCallback(
    (mutationValue?: CommerceRecipient, mutationType?: MutationType) => {
      setMutationCount((current) => {
        const newCount = current - 1

        if (!mutationValue || !mutationType) return Math.max(0, newCount)

        setMutationList((currentMutationList) => {
          const newMutationList = [
            ...currentMutationList,
            { ...mutationValue, mutationType },
          ]

          if (newCount >= 1) {
            return newMutationList
          }

          updateOrderRecipientsStore(newMutationList)

          return []
        })

        return Math.max(0, newCount)
      })
    },
    [dispatch, updateOrderRecipientsStore]
  )

  return (
    <>
      <OrderFieldRecipients
        dataTId="order-field-recipients-to"
        label={i18next.t('shoppingLabels.to')}
        onChange={onChange}
        onChangeEnd={onChangeEnd}
        emailField="To"
        readOnly={!order?.isEditAllowed}
      />

      <OrderFieldRecipients
        dataTId="order-field-recipients-cc"
        label={i18next.t('shoppingLabels.cc')}
        onChange={onChange}
        onChangeEnd={onChangeEnd}
        emailField="CC"
        disabled
      />
    </>
  )
}

export default OrderRecipients
