import React from 'react'
import { Box, Text } from 'theme-ui'
import { CrossReference } from '../../../types/partTypes'

interface PartCrossReferencesTableProps {
  crossReferences: CrossReference[]
}

export const PartCrossReferencesTable = ({
  crossReferences,
}: PartCrossReferencesTableProps) => {
  let lastVendor = ''

  if (!crossReferences?.length) return null

  return (
    <Box
      as="dl"
      sx={{
        display: 'grid',
        gridTemplateColumns: ['auto auto', null, '1fr 1fr'],
        columnGap: 2,
      }}
    >
      {crossReferences.map(({ vendor, vendorPartNumber }) => {
        const hideVendorOnMobile =
          lastVendor === vendor ? ['none', 'none', 'block'] : []

        lastVendor = vendor

        return (
          <Box
            key={`${vendor}-${vendorPartNumber}`}
            sx={{
              gridColumn: '1 / -1',
              borderBlockEnd: '1px solid',
              borderColor: 'gray.1',
              display: 'grid',
              gridTemplateColumns: 'subgrid',
              paddingBlock: 2,
            }}
          >
            <Text
              as="strong"
              variant="bodyContent"
              color="gray.3"
              sx={{
                fontWeight: 'bold',
                display: hideVendorOnMobile,
              }}
            >
              {vendor}
            </Text>

            <Text
              as="span"
              variant="bodyContent"
              sx={{
                textAlign: ['right', 'right', 'left'],
              }}
            >
              {vendorPartNumber}
            </Text>
          </Box>
        )
      })}
    </Box>
  )
}

export default PartCrossReferencesTable
