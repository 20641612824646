import React, { useContext } from 'react'
import i18next from 'i18next'
import { UserContext } from '../../../providers/UserProvider'
import { loggedIn } from '../../../helpers/userHelper'
import TableColumnTitle from '../../molecules/Table/TableColumnTitle'
import TableGridHead from '../../molecules/Table/TableGridHead'

const SearchResultsLineViewColumnTitles = () => {
  const { user } = useContext(UserContext)

  return (
    <TableGridHead>
      <TableColumnTitle>{i18next.t('commonLabels.product')}</TableColumnTitle>

      <TableColumnTitle>{i18next.t('commonLabels.specifications')}</TableColumnTitle>

      <TableColumnTitle>{i18next.t('commonLabels.brand')}</TableColumnTitle>

      {loggedIn(user) && (
        <TableColumnTitle>{i18next.t('stockLabels.availability')}</TableColumnTitle>
      )}

      {loggedIn(user) && (
        <TableColumnTitle>{i18next.t('partSalesLabels.price')}</TableColumnTitle>
      )}
    </TableGridHead>
  )
}

export default SearchResultsLineViewColumnTitles
