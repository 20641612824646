import React, { useContext } from 'react'
import i18next from 'i18next'
import { Box, Text } from 'theme-ui'
import { LinkField } from '../../../../../types/layoutService'
import { AssemblySearchContext } from '../../../../../providers/AssemblySearchContextProvider'
import AssemblySearchLine from './AssemblySearchLine'
import TableColumnTitle from '../../../../molecules/Table/TableColumnTitle'
import ListBase from '../../../../atoms/ListBase/ListBase'
import PartLineImage from '../../../../molecules/PartLine/PartLineImage'
import PartLineLabelValue from '../../../../molecules/PartLine/PartLineLabelValue'
import PartLineAction from '../../../../molecules/PartLine/PartLineAnchor'
import { resolvePrimaryImage } from '../../../../../helpers/commerceApiHelpers'
import { AssemblyFulfilled } from '../../../../../hooks/services/rest/ecommerce/useAssemblySearchService'
import Flow from '../../../../atoms/Flow/Flow'
import TableGridHead from '../../../../molecules/Table/TableGridHead'
import TableGridBody from '../../../../molecules/Table/TableGridBody'

interface AssembliesSearchLineViewProps {
  assemblyPage: LinkField
}

const AssembliesSearchLineView = ({
  assemblyPage,
}: AssembliesSearchLineViewProps) => {
  const { assemblies } = useContext(AssemblySearchContext)

  if (!assemblies || !assemblies.length) return null

  const assemblyPrimaryImage = (assembly: AssemblyFulfilled['assembly']) =>
    assembly && resolvePrimaryImage(assembly)

  return (
    <>
      <Box
        sx={{
          display: [null, 'none'],
        }}
      >
        <ListBase
          sx={{
            borderBlockStart: '1px solid',
            borderColor: 'gray.1',
          }}
        >
          {assemblies.map((assembly) => (
            <Box
              as="li"
              sx={{
                paddingBlock: 3,
                borderBlockEnd: '1px solid',
                borderColor: 'gray.1',
              }}
              key={assembly.searchAssembly.bomId}
            >
              <Box
                as="article"
                sx={{
                  position: 'relative',
                }}
              >
                <Flow>
                  <PartLineImage
                    src={assemblyPrimaryImage(assembly.assembly)?.thumbnailImageUrl}
                    alt={assembly.searchAssembly.bomDescription}
                  />
                  <div>
                    <PartLineAction
                      href={`${assemblyPage?.url}/${assembly.uri}`}
                      data-t-id={`assembly-search-line-assembly-link-id-${assembly.searchAssembly.bomId}`}
                      sx={{
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          inset: 0,
                        },
                      }}
                    >
                      <Text as="h4" variant="heading4" color="gray.3">
                        {assembly.searchAssembly.bomDescription}
                      </Text>

                      <PartLineLabelValue
                        label={i18next.t('partLabels.referenceNr')}
                      >
                        {assembly.searchAssembly.bomId}
                      </PartLineLabelValue>
                    </PartLineAction>
                  </div>
                  <div>
                    <PartLineImage
                      src={assembly.assembly?.brand?.image?.thumbnailImageUrl}
                      alt={assembly.assembly?.brand?.description}
                      title={assembly.assembly?.brand?.description}
                    />
                  </div>
                </Flow>
              </Box>
            </Box>
          ))}
        </ListBase>
      </Box>
      <Box
        sx={{
          display: ['none', 'block'],
        }}
      >
        <Box
          as="table"
          sx={{
            display: 'grid',
            gridTemplateColumns: ['auto auto', null, '5fr 2fr', null, '1fr 1fr'],
            borderColor: 'gray.1',
            columnGap: 2,
          }}
        >
          <TableGridHead>
            <TableColumnTitle>{i18next.t('commonLabels.product')}</TableColumnTitle>
            <TableColumnTitle>{i18next.t('commonLabels.brand')}</TableColumnTitle>
          </TableGridHead>
          <TableGridBody>
            {assemblies.map((assembly) => (
              <AssemblySearchLine
                key={assembly.searchAssembly.bomId}
                assemblyPage={assemblyPage}
                {...assembly}
              />
            ))}
          </TableGridBody>
        </Box>
      </Box>
    </>
  )
}

export default AssembliesSearchLineView
