import React, { forwardRef } from 'react'
import HotjarWhitelist from '../../atoms/HotjarWhitelist/HotjarWhitelist'
import Input, { InputProps } from '../../atoms/inputs/Input/Input'

interface SearchBarInputProps extends Omit<InputProps, 'css'> {
  hasUploadButton?: boolean
}
export const SEARCH_BAR_HEIGHT = 40

const SearchBarInput = forwardRef<HTMLInputElement, SearchBarInputProps>(
  ({ required = true, hasUploadButton = false, sx, ...inputProps }, ref) => (
    <HotjarWhitelist>
      <Input
        autoComplete="on"
        ref={ref}
        required={required}
        sx={{
          color: 'text',
          height: `${SEARCH_BAR_HEIGHT}px`,
          p: 2,
          paddingInlineStart: `${SEARCH_BAR_HEIGHT}px`,
          paddingInlineEnd: hasUploadButton ? `${SEARCH_BAR_HEIGHT}px` : null,
          fontSize: 1,
          outline: 'none',
          borderRadius: `${SEARCH_BAR_HEIGHT}px`,
          border: 'none',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          '::placeholder': {
            color: 'gray.2',
          },
          '::-webkit-calendar-picker-indicator': {
            opacity: 1,
          },
          ...sx,
        }}
        {...inputProps}
      />
    </HotjarWhitelist>
  )
)

export default SearchBarInput
