import { AxiosInstance, AxiosResponse, CancelToken } from 'axios'
import { DafDefaultQueryParams, UserProps } from '../../../../types/userProps'
import { createRequestConfig } from '../../../../helpers/axiosHelper'
import {
  REWARD_TYPE_CREDIT_MERCHANDISE,
  REWARD_TYPE_PRODUCT_MERCHANDISE,
} from '../../../../constants/loyaltyConstants'

interface CreditValue {
  value: number
  currencyCode: string
}

export interface Reward {
  campaignId: number
  rewardId: number
  type:
    | typeof REWARD_TYPE_CREDIT_MERCHANDISE
    | typeof REWARD_TYPE_PRODUCT_MERCHANDISE
  creditValue: CreditValue
  productName: string
  productDetailUrl: string
  productImageUrl: string
  costPriceInPoints: number
  isAvailableForMe: boolean
}

export interface RewardsStatisticsProps {
  totalCount: number
}

export interface RewardsProps {
  statistics: RewardsStatisticsProps
  currentPointsBalance: number
  rewards: Reward[]
}

interface Message {
  message: string
  severity: string
  code: string
  args: string[]
  argsObj: Record<string, any>
}

export interface RewardsResultData {
  result: RewardsProps
  messages: Message
}

interface Paging {
  pageNumber: number
  partsPerPage: number
}

interface FilterValue {
  value: string
}

interface Filter {
  id: string
  values: FilterValue[]
}

interface RequestBody {
  keyword?: string
  cultureCode?: string
  countryCode?: string
  paging: Paging
  filters?: Filter[]
}

export const getRewardsInformation = (
  requestBody: RequestBody,
  queryParams: DafDefaultQueryParams,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<RewardsResultData>> =>
  client.post('customer/loyalty/rewards/merchandise-vouchers', requestBody, {
    params: queryParams,
    cancelToken,
    ...createRequestConfig(user),
  })

interface RewardData {
  result: {
    voucherCodes: string[]
  }
  messages: Message[]
}

export const redeemReward = (
  campaignId: number | undefined,
  rewardId: number | undefined,
  rewardQuantity: number,
  queryParams: DafDefaultQueryParams,
  client: AxiosInstance,
  cancelToken: CancelToken,
  user?: UserProps
): Promise<AxiosResponse<RewardData>> =>
  client.post(
    '/customer/loyalty/redeem',
    {
      campaignId,
      rewardId,
      rewardQuantity,
    },
    {
      params: queryParams,
      cancelToken,
      ...createRequestConfig(user),
    }
  )
