import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { Box } from 'theme-ui'
import ActionsStatistics from '../../molecules/ActionsStatistics/ActionsStatistics'
import fetchDafCompanyVehicles, {
  DafCompanyVehiclesData,
} from '../../../services/rest/ecommerce/dafCompanyVehicles'
import useAxiosClient from '../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../providers/AxiosClientProvider'
import { UserContext } from '../../../providers/UserProvider'
import PlansAndOtherServices from '../../molecules/PlansAndOtherServices/PlansAndOtherServices'
import Container from '../../atoms/Container/Container'
import MultisupportUrgentTasks from '../MultisupportUrgentTasks/MultisupportUrgentTasks'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../types/userProps'
import { QUERY_KEY_DAF_COMPANY_VEHICLES } from '../../../constants/queryKeyConstants'

const MultiSupportDashboard = () => {
  const [dafCompanyVehicles, setDafCompanyVehicles] =
    useState<null | DafCompanyVehiclesData>(null)
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)

  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const getDafCompanyVehicles = async () => {
    const { token } = axios.CancelToken.source()
    const response = await fetchDafCompanyVehicles(
      client,
      dafVehicleDetailQueryParams,
      token,
      user
    )
    return response.data
  }
  const { isFetching, data: dafCompanyVehiclesData } = useQuery({
    queryKey: [QUERY_KEY_DAF_COMPANY_VEHICLES],
    queryFn: getDafCompanyVehicles,
    enabled: !!actingCompanyId && !!actingSupplierId,
  })

  useEffect(() => {
    if (dafCompanyVehiclesData) {
      setDafCompanyVehicles(dafCompanyVehiclesData)
    }
  }, [dafCompanyVehiclesData])

  return (
    <>
      <Container>
        <Box
          sx={{
            marginTop: [0, 0, 0, '-200px'],
            marginBottom: [4, 4, 4, 7],
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridAutoRows: ['none', 'none', '1fr'],
              gridTemplateColumns: ['100%', '100%', '66% 33%'],
              gap: 4,
            }}
          >
            <ActionsStatistics
              isFetching={isFetching}
              dafCompanyVehicles={dafCompanyVehicles}
            />

            <MultisupportUrgentTasks
              isFetching={isFetching}
              vehicleData={dafCompanyVehiclesData}
            />
          </Box>

          <Box
            sx={{
              marginTop: 4,
              display: 'grid',
              gridAutoRows: ['none', 'none', '1fr'],
              gridTemplateColumns: ['100%', '100%', '66% 33%'],
              gap: 4,
            }}
          >
            <Box>
              <PlansAndOtherServices
                isFetching={isFetching}
                dafCompanyVehicles={dafCompanyVehicles}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default MultiSupportDashboard
