import { COLORS } from '../constants/themeConstants'
import { Vehicle } from '../services/rest/ecommerce/dafCompanyVehicles'
import {
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_MONTH,
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_TO_TWO_MONTHS,
  MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE,
  VEHICLE_UPGRADE_ATTENTION,
  VEHICLE_UPGRADE_IMPORTANT,
  VEHICLE_UPGRADE_WARNING,
} from '../constants/dafCompanyVehicleConstants'

export const dafVehicleActionColorHelper = (
  vehicleActionStatus: number,
  inputMileage: boolean
) => {
  if (vehicleActionStatus === 1 || inputMileage) return COLORS.ALERT
  if (vehicleActionStatus === 2) return COLORS.PROMOTION
  if (vehicleActionStatus === 3) return COLORS.ORANGE
  return 'transparent'
}

export const dafVehicleMaintenanceColorHelper = (vehicle: Vehicle) => {
  if (
    vehicle.applicableFilters.maintenance[0] ===
      MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_OVERDUE ||
    vehicle.applicableFilters.vehicleUpgrade?.[0] === VEHICLE_UPGRADE_WARNING
  ) {
    return COLORS.ALERT
  }

  if (
    vehicle.applicableFilters.maintenance[0] ===
      MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_MONTH ||
    vehicle.applicableFilters.vehicleUpgrade?.[0] === VEHICLE_UPGRADE_IMPORTANT
  ) {
    return COLORS.PROMOTION
  }

  if (
    vehicle.applicableFilters.maintenance[0] ===
      MY_FLEET_LIST_FILTER_VALUE_MAINTENANCE_DUE_IN_ONE_TO_TWO_MONTHS ||
    vehicle.applicableFilters.vehicleUpgrade?.[0] === VEHICLE_UPGRADE_ATTENTION
  ) {
    return COLORS.ORANGE
  }

  return 'transparent'
}
