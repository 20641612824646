import React, { useContext } from 'react'
import { PartFulFilled } from '../../../../../hooks/services/rest/ecommerce/useFulfilledPartSearchService'
import { LinkField, TextField } from '../../../../../types/layoutService'
import SearchResultsLineViewColumnTitles from '../../SearchResultsLineViewColumnTitles'
import { Box, Flex, Text } from 'theme-ui'
import ListBase from '../../../../atoms/ListBase/ListBase'
import PartLineProductImage from '../../../../molecules/PartLine/PartLineProductImage'
import ClickableWrapper from '../../../../atoms/ClickableWrapper/ClickableWrapper'
import PartLineBasicInformation from '../../../../molecules/PartLine/PartLineBasicInformation'
import { measurePartClick } from '../../../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'
import { CategoriesContext } from '../../../../../providers/CategoriesProvider'
import { MATCH_TYPE_ALTERNATIVE } from '../../../../../constants/searchConstants'
import { getPartAttributesWithPackagingQuantity } from '../../../../../helpers/graphPartHelper'
import PartLinePartAttributes from '../../../../molecules/PartLine/PartLinePartAttributes'
import PartLineBrand from '../../../../molecules/PartLine/PartLineBrand'
import PartLineStockInformation from '../../../../molecules/PartLine/PartLineStockInformation'
import PartSalesInformation from '../../../../molecules/SalesInformation/PartSalesInformation'
import { Part } from '../../../../../types/dafResponseProps'
import { COLORS } from '../../../../../constants/themeConstants'
import { loggedIn } from '../../../../../helpers/userHelper'
import PartSalesInformationProvider from '../../../../../providers/PartSalesInformationProvider'
import { SearchPart } from '../../../../../services/rest/ecommerce/partSearch'
import { UserContext } from '../../../../../providers/UserProvider'
import SearchResultsPage from '../../SearchResultsPage'
import Flow from '../../../../atoms/Flow/Flow'
import IconWrapper from '../../../../atoms/IconWrapper/IconWrapper'
import { FONT_AWESOME_ICONS } from '../../../../../constants/iconConstants'
import i18next from 'i18next'
import TableGridBody from '../../../../molecules/Table/TableGridBody'

interface PartSearchLineViewProps {
  parts?: PartFulFilled[]
  partPage: LinkField
  supersessionText: TextField
}

export const partSearchGridTemplate = {
  columnSizes: ['1fr', null, '1fr 2fr 4fr 1fr', null, null, '1fr 2fr 4fr 4fr'], // css prop: grid-template-columns
  columnSizesLoggedIn: ['1fr', null, '3fr 4fr 1fr 2fr 3fr'], // css prop: grid-template-columns
}

const PartSearchLineView = ({
  parts,
  partPage,
  supersessionText,
}: PartSearchLineViewProps) => {
  if (!parts?.length) return null
  const { user } = useContext(UserContext)

  const { categories } = useContext(CategoriesContext)

  const isAlternative = (searchPart: SearchPart) =>
    searchPart.matchMetaData?.type &&
    searchPart.matchMetaData.type === MATCH_TYPE_ALTERNATIVE

  const sortedPartAttributes = (part?: Part) =>
    part?.specification?.partAttributes && part?.specification?.partAttributes.length
      ? part?.specification?.partAttributes.sort((a, b) => a.priority - b.priority)
      : []

  const attributes = (part?: Part) =>
    getPartAttributesWithPackagingQuantity(
      sortedPartAttributes(part),
      part?.specification?.packaging
    )

  const measureClick = (part?: Part, index?: number) =>
    measurePartClick(
      part?.partNumber,
      part?.englishDescription,
      part?.brand?.description,
      part?.category?.mainCategoryId,
      part?.category?.subCategoryId,
      index,
      categories,
      DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_LINE
    )

  return (
    <>
      <Box
        sx={{
          display: [null, null, 'none'],
        }}
      >
        <ListBase
          sx={{
            borderBlockStart: '1px solid',
            borderColor: 'gray.1',
          }}
        >
          <SearchResultsPage parts={parts}>
            {({ part, searchPart, uri }: PartFulFilled, index: number) => (
              <PartSalesInformationProvider partNumber={searchPart.partNumber}>
                <li data-t-id={`part-search-line-part-number-${part?.partNumber}`}>
                  <Box
                    as="article"
                    sx={{
                      paddingBlock: 3,
                      borderBlockEnd: '1px solid',
                      borderColor: 'gray.1',
                    }}
                  >
                    <Flow>
                      <Box
                        sx={{
                          position: 'relative',
                        }}
                      >
                        <Flow>
                          {isAlternative(searchPart) && (
                            <>
                              <Text
                                variant="heading5"
                                sx={{
                                  display: 'flex',
                                  gap: 3,
                                  alignItems: 'center',
                                }}
                              >
                                <IconWrapper
                                  icon={FONT_AWESOME_ICONS.EXCHANGE}
                                  size={2}
                                />

                                {i18next.t('partLabels.alternative')}
                              </Text>
                            </>
                          )}

                          <PartLineProductImage
                            partNumber={searchPart.partNumber}
                            src={part?.images?.[0].thumbnailImageUrl}
                            alt={part?.description}
                          />

                          <div>
                            <ClickableWrapper
                              as="a"
                              href={`${partPage.url}/${uri}`}
                              onClick={() => measureClick(part, index)}
                              sx={{
                                color: 'inherit',
                                '&:hover, &:active, &:focus': {
                                  textDecoration: 'underline',
                                },
                                '&::after': {
                                  content: '""',
                                  position: 'absolute',
                                  inset: 0,
                                },
                              }}
                            >
                              <PartLineBasicInformation
                                description={part?.description}
                                partNumber={searchPart.partNumber}
                                displayPartNumber={part?.displayPartNumber}
                                matchMetaData={searchPart.matchMetaData}
                                supplierPartNumber={part?.supplierPartNumber}
                                supersessionText={supersessionText}
                              />
                            </ClickableWrapper>
                          </div>
                          <div>
                            <PartLinePartAttributes attributes={attributes(part)} />
                          </div>
                          <div>
                            <PartLineBrand
                              data-t-id="search-result-part-brand"
                              brand={part?.brand}
                            />
                          </div>
                        </Flow>
                      </Box>

                      <div>
                        <PartLineStockInformation
                          partNumber={searchPart.partNumber}
                          displayPartNumber={part?.displayPartNumber}
                          componentOrigin={
                            DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_LINE
                          }
                          categoryId={part?.category?.mainCategoryId}
                          subcategoryId={part?.category?.subCategoryId}
                        />
                      </div>
                      <div>
                        <PartSalesInformation
                          partNumber={searchPart.partNumber}
                          partEnglishDescription={part?.englishDescription}
                          brandName={part?.brand?.description}
                          categoryId={part?.category?.mainCategoryId}
                          subcategoryId={part?.category?.subCategoryId}
                          dataLayerComponentOrigin={
                            DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_LINE
                          }
                          packageUnitQuantity={
                            part?.specification?.packaging?.packageUnitQuantity
                          }
                          initialQuantity={
                            part?.specification?.packaging?.packageUnitQuantity
                          }
                        />
                      </div>
                    </Flow>
                  </Box>
                </li>
              </PartSalesInformationProvider>
            )}
          </SearchResultsPage>
        </ListBase>
      </Box>
      <Box
        sx={{
          display: ['none', null, 'block'],
        }}
      >
        <Box
          as="table"
          sx={{
            display: 'grid',
            gridTemplateColumns: partSearchGridTemplate.columnSizesLoggedIn,
          }}
        >
          <SearchResultsLineViewColumnTitles />

          <TableGridBody>
            <SearchResultsPage parts={parts}>
              {({ part, searchPart, uri }: PartFulFilled, index: number) => (
                <PartSalesInformationProvider partNumber={searchPart.partNumber}>
                  <Box
                    as="tr"
                    sx={{
                      gridColumn: '1 / -1',
                      marginBlockStart: 3,
                      marginBlockEnd: -3,
                    }}
                  >
                    <td>
                      {isAlternative(searchPart) && (
                        <Text
                          variant="heading5"
                          sx={{
                            marginInlineStart: 104,
                            marginBlockEnd: 2,
                            marginBlockStart: -4,
                            paddingBlockStart: 4,
                            display: 'flex',
                            gap: 3,
                            alignItems: 'center',
                          }}
                        >
                          <IconWrapper icon={FONT_AWESOME_ICONS.EXCHANGE} size={2} />

                          {i18next.t('partLabels.alternative')}
                        </Text>
                      )}
                    </td>
                  </Box>
                  <Box
                    data-t-id={`part-search-line-part-number-${part?.partNumber}`}
                    as="tr"
                    sx={{
                      gridColumn: '1 / -1',
                      display: 'grid',
                      gridTemplateColumns: 'subgrid',
                      paddingBlock: 4,
                      borderBlockEnd: '1px solid',
                      borderColor: COLORS.MEDIUM_GRAY,
                      '> td': {
                        gridRow: '1 / 2',
                      },
                      '> td:nth-of-type(2)': {
                        gridColumn: '2 / 3',
                      },
                      '> td:nth-of-type(3)': {
                        gridColumn: '3 / 4',
                      },
                      '> td:nth-of-type(4)': {
                        gridColumn: '4 / 5',
                      },
                    }}
                  >
                    <Box
                      as="td"
                      sx={{
                        gridColumn: '1 / 4',
                        position: 'relative',
                        display: 'grid',
                        gridTemplateColumns: 'subgrid',
                      }}
                    >
                      <Flex
                        sx={{
                          gap: [2, null, null, 6],
                        }}
                      >
                        <PartLineProductImage
                          partNumber={searchPart.partNumber}
                          src={part?.images?.[0].thumbnailImageUrl}
                          alt={part?.description}
                        />

                        <ClickableWrapper
                          as="a"
                          href={`${partPage.url}/${uri}`}
                          onClick={() => measureClick(part, index)}
                          sx={{
                            color: 'inherit',
                            '&:hover, &:active, &:focus': {
                              textDecoration: 'underline',
                            },
                            '&::after': {
                              content: '""',
                              position: 'absolute',
                              insetInline: 0,
                              insetBlock: '-17px',
                            },
                          }}
                        >
                          <PartLineBasicInformation
                            description={part?.description}
                            partNumber={searchPart.partNumber}
                            displayPartNumber={part?.displayPartNumber}
                            matchMetaData={searchPart.matchMetaData}
                            supplierPartNumber={part?.supplierPartNumber}
                            supersessionText={supersessionText}
                          />
                        </ClickableWrapper>
                      </Flex>
                    </Box>

                    <td>
                      <PartLinePartAttributes attributes={attributes(part)} />
                    </td>

                    <td>
                      <PartLineBrand
                        data-t-id="search-result-part-brand"
                        brand={part?.brand}
                      />
                    </td>

                    <td>
                      <PartLineStockInformation
                        partNumber={searchPart.partNumber}
                        displayPartNumber={part?.displayPartNumber}
                        componentOrigin={
                          DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_LINE
                        }
                        categoryId={part?.category?.mainCategoryId}
                        subcategoryId={part?.category?.subCategoryId}
                      />
                    </td>

                    <td>
                      {loggedIn(user) && (
                        <PartSalesInformation
                          partNumber={searchPart.partNumber}
                          partEnglishDescription={part?.englishDescription}
                          brandName={part?.brand?.description}
                          categoryId={part?.category?.mainCategoryId}
                          subcategoryId={part?.category?.subCategoryId}
                          dataLayerComponentOrigin={
                            DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_LINE
                          }
                          packageUnitQuantity={
                            part?.specification?.packaging?.packageUnitQuantity
                          }
                          initialQuantity={
                            part?.specification?.packaging?.packageUnitQuantity
                          }
                        />
                      )}
                    </td>
                  </Box>
                </PartSalesInformationProvider>
              )}
            </SearchResultsPage>
          </TableGridBody>
        </Box>
      </Box>
    </>
  )
}

export default PartSearchLineView
