import React, { useCallback, useContext } from 'react'
import { Box } from 'theme-ui'
import PartListTable from './PartListTable'
import PartListCompact from './PartListCompact'
import ShoppingBasketEditItemSidePanel from '../../../SidePanel/ShoppingBasket/ShoppingBasketEditItemSidePanel'
import { CommerceOrderItem } from '../../../../../types/commerceApi'
import { measurePartClick } from '../../../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'
import { CategoriesContext } from '../../../../../providers/CategoriesProvider'
import { useShoppingBasket } from '../../../../../providers/ShoppingBasketProvider'
import { LinkField } from '../../../../../types/layoutService'
import { getCommercePartUri } from '../../../../../helpers/commerceApiHelpers'

export const getPartHref = (
  partPage: LinkField,
  dealerPartPage: LinkField,
  part: CommerceOrderItem
) => {
  if (part.type === 'DealerOwnAssortment') {
    return `${dealerPartPage?.url}/${part.partNumber}`
  }

  return `${partPage?.url}/${getCommercePartUri(
    part?.category?.mainCategoryId,
    part?.category?.subCategoryId,
    part.partNumber
  )}`
}

const PartList = () => {
  const { categories } = useContext(CategoriesContext)
  const { partPage, dealerPartPage } = useShoppingBasket()

  const measureClick = useCallback(
    (part: CommerceOrderItem, index: number) =>
      measurePartClick(
        part.partNumber,
        part.englishDescription,
        part.brand?.description,
        part.category?.mainCategoryId,
        part.category?.subCategoryId,
        index,
        categories,
        DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_LINE,
        part.price?.netUnitPrice
      ),
    [categories]
  )

  const getHref = useCallback(
    (part: CommerceOrderItem) => getPartHref(partPage, dealerPartPage, part),
    [partPage, dealerPartPage]
  )

  return (
    <>
      <Box
        sx={{
          display: ['none', 'none', 'block'],
        }}
      >
        <PartListTable measureClick={measureClick} getHref={getHref} />
      </Box>
      <Box
        sx={{
          display: [null, null, 'none'],
        }}
      >
        <PartListCompact measureClick={measureClick} getHref={getHref} />
      </Box>

      <ShoppingBasketEditItemSidePanel />
    </>
  )
}

export default PartList
