import { useTheme } from '@emotion/react'
import React, { ReactNode, useRef } from 'react'
import { Flex } from 'theme-ui'
import useOutsideClick from '../../../hooks/useOutsideClick'
import { ThemeProps } from '../../../types/themeProps'
import { ModalVariant } from './Modal'

interface ModalContentProps {
  variant: ModalVariant
  onClose: () => void
  disableOutsideClick?: boolean
  children: ReactNode
}

const variantStyle = (variant: ModalVariant, themeSettings: ThemeProps) =>
  ({
    minimal: {
      width: '545px', // custom width
      maxWidth: `calc(100% - ${themeSettings.space[3] * 2}px)`,
      height: 'auto',
      maxHeight: '100%',
    },
    default: {
      width: ['100%', '100%', '100%', '644px'], // custom width
      height: ['100%', '100%', '100%', undefined],
      maxHeight: ['100%', '100%', '100%', '650px'],
    },
    fullscreen: {
      width: '100svw',
      height: '100svh',
    },
  }[variant])

const ModalContent = ({
  variant,
  onClose,
  disableOutsideClick,
  children,
}: ModalContentProps) => {
  const themeSettings = useTheme()
  const ref = useRef<HTMLElement>(null)

  useOutsideClick(ref, onClose, disableOutsideClick)

  return (
    <Flex
      data-t="ModalContent"
      ref={ref}
      sx={{
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'stretch',
        ...variantStyle(variant, themeSettings),
      }}
    >
      {children}
    </Flex>
  )
}

export default ModalContent
