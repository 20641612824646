import i18next from 'i18next'
import React, { FC, useContext } from 'react'
import { Box, Text } from 'theme-ui'
import { MATCH_TYPE_CROSS_REFERENCE } from '../../../constants/searchConstants'
import { SearchContext } from '../../../providers/SearchContextProvider'
import { TextField } from '../../../types/layoutService'
import { MatchMetaDataProps } from '../../../types/dafResponseProps'

import Supersession from '../Supersession/Supersession'
import PartLineLabelValue from './PartLineLabelValue'
import Highlight from '../../atoms/Highlight/Highlight'

interface PartLineBasicInformationProps {
  partNumber?: string
  description?: string
  displayPartNumber?: string
  matchMetaData?: MatchMetaDataProps
  supplierPartNumber?: string
  supersessionText?: TextField
}

const PartLineBasicInformation: FC<PartLineBasicInformationProps> = ({
  description = '',
  partNumber,
  displayPartNumber,
  matchMetaData,
  supplierPartNumber,
  supersessionText,
}) => {
  const { searchString } = useContext(SearchContext)

  return (
    <div>
      <Text
        as="h4"
        variant="heading4"
        color="gray.3"
        data-t-id="search-result-line-description"
      >
        {description}
      </Text>

      <div>
        {(displayPartNumber || partNumber) && (
          <PartLineLabelValue
            data-t-id="part-number-search-line"
            label={i18next.t('partLabels.partNumberAbbreviation')}
          >
            {displayPartNumber || partNumber}
            {supersessionText && (
              <Box
                sx={{
                  position: 'relative',
                  zIndex: 1,
                }}
              >
                <Supersession
                  partDescription={description}
                  partNumber={partNumber}
                  matchMetaData={matchMetaData}
                  sidePanelText={supersessionText}
                />
              </Box>
            )}
          </PartLineLabelValue>
        )}

        {supplierPartNumber && (
          <PartLineLabelValue
            manPart
            label={i18next.t('partLabels.manufacturerPartNumberAbbreviation')}
          >
            {supplierPartNumber}
          </PartLineLabelValue>
        )}

        {!!matchMetaData && matchMetaData.type === MATCH_TYPE_CROSS_REFERENCE && (
          <PartLineLabelValue
            label={i18next.t('partLabels.crossReferencesAbbreviation')}
          >
            <Highlight search={matchMetaData.basedOn || searchString}>
              {matchMetaData.matchedOn}
            </Highlight>
          </PartLineLabelValue>
        )}
      </div>
    </div>
  )
}

export default PartLineBasicInformation
