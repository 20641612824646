import React, { FC, useContext, useEffect } from 'react'
import {
  VIEW_TYPE_GRID,
  VIEW_TYPE_LINE,
  VIEW_TYPE_TABLE,
} from '../../../../constants/searchConstants'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { PartSearchResultContext } from '../../../../providers/PartSearchResultContextProvider'
import { LinkField, TextField } from '../../../../types/layoutService'
import Paginated from '../../../organisms/Paginated/Paginated'
import PartSearchLineView from './Line/PartSearchLineView'
import PartSearchTableView from './Table/PartSearchTableView'
import PartSearchGridView from './Grid/PartSearchGridView'
import SpinnerWithLabel from '../../../atoms/SpinnerWithLabel/SpinnerWithLabel'
import { pushToDataLayer } from '../../../../helpers/analyticsHelper'
import { DATA_LAYER } from '../../../../constants/dataLayerConstants'
import { getEnglishCategoryName } from '../../../../helpers/categoryHelper'
import { CategoriesContext } from '../../../../providers/CategoriesProvider'

interface PartSearchResultsProps {
  partPage: LinkField
  supersessionText: TextField
  loadingLabel: TextField
}

const PartSearchResults: FC<PartSearchResultsProps> = ({
  partPage,
  supersessionText,
  loadingLabel,
}) => {
  const {
    viewType,
    parts,
    availableClassInformation,
    pagination,
    fetching: fetchingParts,
  } = useContext(PartSearchResultContext)
  const { categories } = useContext(CategoriesContext)

  useEffect(() => {
    if (parts && !fetchingParts) {
      pushToDataLayer({
        event: DATA_LAYER.EVENT_NAME.VIEW_ITEM_LIST,
        ecommerce: {
          item_list_name: `search result ${viewType}`,
          items: parts.map((part) => ({
            item_id: part.part?.partNumber,
            item_name: part.part?.englishDescription,
            item_brand: part.part?.brand?.description,
            item_category: getEnglishCategoryName(part.part?.category, categories),
            doa_product: false,
          })),
        },
      })
    }
  }, [categories, fetchingParts, parts, viewType])

  if (fetchingParts)
    return (
      <SpinnerWithLabel
        label={getTextFieldValue(loadingLabel)}
        sx={{
          width: 'max-content',
          position: 'sticky',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
    )

  if (!parts) return null

  if (viewType === VIEW_TYPE_TABLE) {
    return (
      <PartSearchTableView
        parts={parts}
        availableClassInformation={availableClassInformation}
        fetching={fetchingParts}
        partPage={partPage}
        supersessionText={supersessionText}
      />
    )
  }

  return (
    <Paginated
      fetching={fetchingParts}
      fetchingLabel={getTextFieldValue(loadingLabel)}
      {...pagination}
    >
      {viewType === VIEW_TYPE_LINE && (
        <PartSearchLineView
          parts={parts}
          partPage={partPage}
          supersessionText={supersessionText}
        />
      )}
      {viewType === VIEW_TYPE_GRID && (
        <PartSearchGridView parts={parts} partPage={partPage} />
      )}
    </Paginated>
  )
}

export default PartSearchResults
