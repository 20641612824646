import React from 'react'
import { Box, FlexProps } from 'theme-ui'

export interface TableRowProps
  extends Pick<FlexProps, Exclude<keyof FlexProps, 'css'>> {}

const TableRow = ({ children, sx }: TableRowProps) => (
  <Box
    as="tr"
    sx={{
      gridColumn: '1 / -1',
      display: 'grid',
      gridTemplateColumns: 'subgrid',
      paddingBlock: 3,
      borderBlockEnd: '1px solid',
      borderColor: 'gray.1',
      ...sx,
    }}
  >
    {children}
  </Box>
)

export default TableRow
