import React, { FC } from 'react'
import { Box, FlexProps } from 'theme-ui'

interface TableBodyProps extends Pick<FlexProps, Exclude<keyof FlexProps, 'css'>> {}

const TableBody: FC<TableBodyProps> = ({ children }) => (
  <Box
    as="tbody"
    sx={{
      gridColumn: '1 / -1',
      display: 'grid',
      gridTemplateColumns: 'subgrid',
    }}
  >
    {children}
  </Box>
)

export default TableBody
