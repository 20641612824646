import React from 'react'
import { Box } from 'theme-ui'

interface FlowProps {
  children?: React.ReactNode
}

const Flow = ({ children }: FlowProps) => {
  return (
    <Box
      className="flow"
      sx={{
        '> * + *': {
          marginBlockStart: 3,
        },
      }}
    >
      {children}
    </Box>
  )
}

export default Flow
