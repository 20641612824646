import TableColumnTitle from '../../molecules/Table/TableColumnTitle'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { Text, Box } from 'theme-ui'
import i18next from 'i18next'
import dayjs from 'dayjs'
import Button from '../../atoms/Button/Button'
import { ICONS } from '../../../constants/iconConstants'
import React from 'react'
import { VoucherCodesOverviewDatasource } from './VoucherCodesOverview'
import { addToast } from '../../../actions/toastActions'
import { useDispatch } from 'react-redux'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { Voucher } from '../../../services/rest/ecommerce/loyalty/vouchers'
import { formatNumber } from '../../../helpers/numberFormatHelper'
import VoucherCodeProductName from '../../molecules/VoucherCode/VoucherCodeProductName'
import VoucherCodeStatus from '../../molecules/VoucherCode/VoucherCodeStatus'
import NoResults from '../../molecules/NoResults/NoResults'
import TableGridHead from '../../molecules/Table/TableGridHead'
import TableGridBody from '../../molecules/Table/TableGridBody'
import TableGridRow from '../../molecules/Table/TableGridRow'

interface VoucherCodesOverviewTableProps {
  vouchers: Voucher[]
  datasource: VoucherCodesOverviewDatasource
}

const VoucherCodesOverviewTable = ({
  vouchers,
  datasource,
}: VoucherCodesOverviewTableProps) => {
  const dispatch = useDispatch()
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  const writeClipboardText = async (text: string) => {
    await navigator.clipboard.writeText(text)

    dispatch(
      addToast({
        id: text,
        message: i18next.t('commonLabels.copiedToClipboard'),
      })
    )
  }

  return (
    <>
      <Text>
        <Box
          as="table"
          sx={{
            display: 'grid',
            gridTemplateColumns: '1.8fr 0.7fr 1fr 1fr 1fr min-content min-content',
            alignItems: 'center',
            borderInline: '1px solid',
            borderColor: 'gray.1',
          }}
        >
          <TableGridHead hasBorderInline>
            <TableColumnTitle>
              {getTextFieldValue(datasource?.voucher)}
            </TableColumnTitle>
            <TableColumnTitle>
              {getTextFieldValue(datasource?.points)}
            </TableColumnTitle>
            <TableColumnTitle>
              {getTextFieldValue(datasource?.issued)}
            </TableColumnTitle>
            <TableColumnTitle>
              {getTextFieldValue(datasource?.expires)}
            </TableColumnTitle>
            <TableColumnTitle>
              {getTextFieldValue(datasource?.code)}
            </TableColumnTitle>
            <TableColumnTitle>
              {getTextFieldValue(datasource?.status)}
            </TableColumnTitle>
          </TableGridHead>
          {vouchers?.length > 0 && (
            <TableGridBody>
              {vouchers?.map((voucher) => (
                <TableGridRow
                  key={voucher.uniqueCode}
                  sx={{
                    alignItems: 'center',
                    borderBlockEnd: '1px solid',
                    borderColor: 'gray.1',
                    paddingBlock: 12,
                    paddingInline: 4,
                  }}
                >
                  <td>
                    <VoucherCodeProductName voucher={voucher} />
                  </td>

                  <td>
                    <Text variant="bodySmall" color="gray.3">
                      {formatNumber(voucher.costPriceInPoints, cultureCode)}
                    </Text>
                  </td>
                  <td>
                    <Text variant="bodySmall" color="gray.3">
                      {dayjs(voucher.issuedDate).format('DD-MM-YYYY')}
                    </Text>
                  </td>
                  <td>
                    <Text variant="bodySmall" color="gray.3">
                      {dayjs(voucher.expiresDate).format('DD-MM-YYYY')}
                    </Text>
                  </td>
                  <td>
                    <Text variant="bodySmall" color="gray.3">
                      <Button
                        onClick={() => writeClipboardText(voucher.uniqueCode)}
                        color="primary"
                        variant="infinite"
                        iconPosition="end"
                        icon={ICONS.COPY}
                        disabled={voucher.status !== 'Active'}
                      >
                        {voucher.uniqueCode}
                      </Button>
                    </Text>
                  </td>
                  <td>
                    <VoucherCodeStatus voucher={voucher} />
                  </td>
                  <td>
                    {voucher.productDetailUrl && (
                      <Button
                        as="a"
                        href={voucher.productDetailUrl}
                        target="_blank"
                        icon={ICONS.EXIT}
                        iconSize={4}
                        iconPosition="end"
                        variant="infinite"
                        color="primary"
                      />
                    )}
                  </td>
                </TableGridRow>
              ))}
            </TableGridBody>
          )}
        </Box>
      </Text>

      {vouchers?.length === 0 && (
        <Box sx={{ width: '100%', paddingBlock: 6 }}>
          <NoResults
            title={getTextFieldValue(datasource?.noResultsTitle)}
            description={getTextFieldValue(datasource?.noResultsDescription)}
          />
        </Box>
      )}
    </>
  )
}

export default VoucherCodesOverviewTable
