import React, { FC, useContext } from 'react'
import { Box, Text } from 'theme-ui'
import i18next from 'i18next'
import { parseDate } from '../../../helpers/dateHelper'
import { LocalizationContext } from '../../../providers/LocalizationProvider'
import { TextField } from '../../../types/layoutService'
import useCurrencyFormatter from '../../../hooks/useCurrencyFormatter'
import { AvailableCoupon } from '../../../hooks/services/graphql/usePartSalesInformationService'
import { PartSalesInformationContext } from '../../../providers/PartSalesInformationProvider'

interface PartMaxCouponTableProps {
  messages: TextField
  coupon: AvailableCoupon[]
}

const PartMaxCouponTable: FC<PartMaxCouponTableProps> = ({ coupon, messages }) => {
  const { priceInformation } = useContext(PartSalesInformationContext)
  const { dateFormatter } = useContext(LocalizationContext)

  const currencyFormatter = useCurrencyFormatter(priceInformation?.price)

  // always take first coupon as discussed with Nicole de Bie
  const { couponNumber, discountValue, endDate, numberOfCoupons } = coupon[0]

  const couponMessages = messages.value.split(/\r?\n/)
  const endDateFormatted = dateFormatter(parseDate(endDate))

  return (
    <Box
      as="dl"
      sx={{
        display: 'grid',
        gridTemplateColumns: ['auto auto', null, '1fr 1fr'],
        columnGap: 2,
      }}
    >
      {couponNumber && (
        <Box
          sx={{
            gridColumn: '1 / -1',
            borderBlockEnd: '1px solid',
            borderColor: 'gray.1',
            display: 'grid',
            gridTemplateColumns: 'subgrid',
            paddingBlock: 2,
          }}
        >
          <Text
            as="strong"
            variant="bodyContent"
            sx={{
              fontWeight: 'bold',
            }}
            color="gray.3"
          >
            {i18next.t('partSalesLabels.number')}
          </Text>
          <Text
            as="span"
            variant="bodyContent"
            sx={{
              textAlign: ['right', 'right', 'left'],
            }}
          >
            {couponNumber}
          </Text>
        </Box>
      )}

      {discountValue && (
        <Box
          sx={{
            gridColumn: '1 / -1',
            borderBlockEnd: '1px solid',
            borderColor: 'gray.1',
            display: 'grid',
            gridTemplateColumns: 'subgrid',
            paddingBlock: 2,
          }}
        >
          <Text
            as="strong"
            variant="bodyContent"
            color="gray.3"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {i18next.t('partSalesLabels.discount')}
          </Text>
          <Text
            as="span"
            variant="bodyContent"
            sx={{
              textAlign: ['right', 'right', 'left'],
            }}
          >
            {currencyFormatter(discountValue)}
          </Text>
        </Box>
      )}

      {endDate && (
        <Box
          sx={{
            gridColumn: '1 / -1',
            borderBlockEnd: '1px solid',
            borderColor: 'gray.1',
            display: 'grid',
            gridTemplateColumns: 'subgrid',
            paddingBlock: 2,
          }}
        >
          <Text
            as="strong"
            variant="bodyContent"
            color="gray.3"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {i18next.t('partSalesLabels.endDate')}
          </Text>
          <Text
            as="span"
            variant="bodyContent"
            sx={{
              textAlign: ['right', 'right', 'left'],
            }}
          >
            {endDateFormatted}
          </Text>
        </Box>
      )}

      {numberOfCoupons && (
        <Box
          sx={{
            gridColumn: '1 / -1',
            borderBlockEnd: '1px solid',
            borderColor: 'gray.1',
            display: 'grid',
            gridTemplateColumns: 'subgrid',
            paddingBlock: 2,
          }}
        >
          <Text
            as="strong"
            variant="bodyContent"
            color="gray.3"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {i18next.t('partSalesLabels.numberAvailable')}
          </Text>
          <Text
            variant="bodyContent"
            sx={{
              textAlign: ['right', 'right', 'left'],
            }}
          >
            {numberOfCoupons}
          </Text>
        </Box>
      )}

      {!!couponMessages?.length &&
        couponMessages.map((message) => (
          <>
            <Box
              key={message}
              sx={{
                gridColumn: '1 / -1',
                borderBlockEnd: '1px solid',
                borderColor: 'gray.1',
                display: 'grid',
                gridTemplateColumns: 'subgrid',
                paddingBlock: 2,
              }}
            >
              <Text
                variant="bodyContent"
                sx={{
                  textAlign: 'start',
                }}
              >
                {message}
              </Text>
            </Box>
            <div></div>
          </>
        ))}
    </Box>
  )
}
export default PartMaxCouponTable
