import React, { FC } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { DealerPartFulfilled } from '../../../../../hooks/services/rest/ecommerce/useDealerPartSearchService'
import { LinkField } from '../../../../../types/layoutService'
import ListBase from '../../../../atoms/ListBase/ListBase'
import Flow from '../../../../atoms/Flow/Flow'
import PartLineProductImage from '../../../../molecules/PartLine/PartLineProductImage'
import ClickableWrapper from '../../../../atoms/ClickableWrapper/ClickableWrapper'
import PartLineBasicInformation from '../../../../molecules/PartLine/PartLineBasicInformation'
import PartLineBrand from '../../../../molecules/PartLine/PartLineBrand'
import PartLineStockInformation from '../../../../molecules/PartLine/PartLineStockInformation'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'
import SearchResultsLineViewColumnTitles from '../../SearchResultsLineViewColumnTitles'
import { COLORS } from '../../../../../constants/themeConstants'
import { partSearchGridTemplate } from '../../PartSearch/Line/PartSearchLineView'
import { measureDealerPartClick } from '../../../../../helpers/analyticsHelper'
import { DealerPart } from '../../../../../hooks/services/graphql/useDealerPartListService'
import { SearchDealerPart } from '../../../../../services/rest/ecommerce/dealerPartSearch'
import DealerPartSalesInformation from '../../../../molecules/SalesInformation/DealerPartSalesInformation'
import i18next from 'i18next'
import TableGridBody from '../../../../molecules/Table/TableGridBody'

interface DealerPartSearchLineViewProps {
  dealerParts?: DealerPartFulfilled[]
  dealerPartPage: LinkField
}

const DealerPartSearchLineView: FC<DealerPartSearchLineViewProps> = ({
  dealerParts,
  dealerPartPage,
}) => {
  if (!dealerParts?.length) return null

  const measureClick = (
    searchDealerPart: SearchDealerPart,
    dealerPart?: DealerPart,
    index?: number
  ) =>
    measureDealerPartClick(
      searchDealerPart.partNumber,
      dealerPart?.partName,
      dealerPart?.brandName,
      index,
      DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_LINE
    )

  return (
    <>
      <Box
        sx={{
          display: [null, null, 'none'],
        }}
      >
        <ListBase
          sx={{
            borderBlockStart: '1px solid',
            borderColor: 'gray.1',
          }}
        >
          {dealerParts.map(({ dealerPart, searchDealerPart, uri }, index) => (
            <li
              data-t-id={`part-search-line-part-number-${dealerPart?.partNumber}`}
              key={dealerPart?.partNumber}
            >
              <Box
                as="article"
                sx={{
                  paddingBlock: 3,
                  borderBlockEnd: '1px solid',
                  borderColor: 'gray.1',
                }}
              >
                <Flow>
                  <Box
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <Flow>
                      <PartLineProductImage
                        partNumber={searchDealerPart.partNumber}
                        src={dealerPart?.partImages?.[0].thumbnailImageUrl}
                      />

                      <div>
                        <ClickableWrapper
                          as="a"
                          href={`${dealerPartPage.url}/${uri}`}
                          onClick={() =>
                            measureClick(searchDealerPart, dealerPart, index)
                          }
                          sx={{
                            color: 'inherit',
                            '&:hover, &:active, &:focus': {
                              textDecoration: 'underline',
                            },
                            '&::after': {
                              content: '""',
                              position: 'absolute',
                              inset: 0,
                            },
                          }}
                        >
                          <PartLineBasicInformation
                            description={dealerPart?.partName}
                            partNumber={searchDealerPart.partNumber}
                            matchMetaData={searchDealerPart.matchMetaData}
                            supplierPartNumber={dealerPart?.manufacturerPartNumber}
                          />
                        </ClickableWrapper>
                      </div>
                      <div>
                        <Flex
                          sx={{
                            justifyContent: 'space-between',
                          }}
                        >
                          <Text variant="bodySmall">
                            {i18next.t('commonLabels.specifications')}
                          </Text>
                          <Text
                            variant="bodySmall"
                            sx={{
                              color: 'gray.2',
                            }}
                          >
                            {dealerPart?.partProperties}
                          </Text>
                        </Flex>
                      </div>
                      <div>
                        <Flex
                          sx={{
                            justifyContent: 'space-between',
                          }}
                        >
                          <Text variant="bodySmall">
                            {i18next.t('commonLabels.brand')}
                          </Text>

                          <PartLineBrand
                            data-t-id="search-result-part-brand"
                            brand={
                              dealerPart
                                ? { description: dealerPart?.brandName }
                                : undefined
                            }
                          />
                        </Flex>
                      </div>
                    </Flow>
                  </Box>
                  <PartLineStockInformation
                    partNumber={dealerPart?.partNumber}
                    componentOrigin={DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_LINE}
                    isDealer
                  />
                  <div>
                    <DealerPartSalesInformation
                      partNumber={dealerPart?.partNumber}
                    />
                  </div>
                </Flow>
              </Box>
            </li>
          ))}
        </ListBase>
      </Box>
      <Box
        sx={{
          display: ['none', null, 'block'],
        }}
      >
        <Box
          as="table"
          sx={{
            display: 'grid',
            gridTemplateColumns: partSearchGridTemplate.columnSizesLoggedIn,
          }}
        >
          <SearchResultsLineViewColumnTitles />

          <TableGridBody>
            {dealerParts.map(
              (
                { dealerPart: dealerPart, searchDealerPart: searchDealerPart, uri },
                index
              ) => (
                <Box
                  key={dealerPart?.partNumber}
                  data-t-id={`part-search-line-part-number-${dealerPart?.partNumber}`}
                  as="tr"
                  sx={{
                    gridColumn: '1 / -1',
                    display: 'grid',
                    gridTemplateColumns: 'subgrid',
                    paddingBlock: 4,
                    borderBlockEnd: '1px solid',
                    borderColor: COLORS.MEDIUM_GRAY,
                    '> td': {
                      gridRow: '1 / 2',
                    },
                    '> td:nth-of-type(2)': {
                      gridColumn: '2 / 3',
                    },
                    '> td:nth-of-type(3)': {
                      gridColumn: '3 / 4',
                    },
                    '> td:nth-of-type(4)': {
                      gridColumn: '4 / 5',
                    },
                  }}
                >
                  <Box
                    as="td"
                    sx={{
                      gridColumn: '1 / 4',
                      position: 'relative',
                      display: 'grid',
                      gridTemplateColumns: 'subgrid',
                    }}
                  >
                    <Flex
                      sx={{
                        gap: [2, null, null, 6],
                      }}
                    >
                      <PartLineProductImage
                        partNumber={searchDealerPart.partNumber}
                        src={dealerPart?.partImages?.[0].thumbnailImageUrl}
                      />

                      <ClickableWrapper
                        as="a"
                        href={`${dealerPartPage.url}/${uri}`}
                        onClick={() =>
                          measureClick(searchDealerPart, dealerPart, index)
                        }
                        sx={{
                          color: 'inherit',
                          '&:hover, &:active, &:focus': {
                            textDecoration: 'underline',
                          },
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            inset: 0,
                          },
                        }}
                      >
                        <PartLineBasicInformation
                          description={dealerPart?.partName}
                          partNumber={searchDealerPart.partNumber}
                          matchMetaData={searchDealerPart.matchMetaData}
                          supplierPartNumber={dealerPart?.manufacturerPartNumber}
                        />
                      </ClickableWrapper>
                    </Flex>
                  </Box>

                  <td>
                    <Text
                      variant="bodySmall"
                      sx={{
                        lineHeight: 'menuList',
                        display: 'inline-block',
                        color: 'gray.2',
                        width: ['auto', 'auto', '100%'],
                      }}
                    >
                      {dealerPart?.partProperties}
                    </Text>
                  </td>

                  <td>
                    <PartLineBrand
                      data-t-id="search-result-part-brand"
                      brand={
                        dealerPart
                          ? { description: dealerPart?.brandName }
                          : undefined
                      }
                    />
                  </td>

                  <td>
                    <PartLineStockInformation
                      partNumber={dealerPart?.partNumber}
                      componentOrigin={
                        DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_LINE
                      }
                      isDealer
                    />
                  </td>

                  <td>
                    <DealerPartSalesInformation
                      partNumber={dealerPart?.partNumber}
                    />
                  </td>
                </Box>
              )
            )}
          </TableGridBody>
        </Box>
      </Box>
    </>
  )
}

export default DealerPartSearchLineView
