import React from 'react'
import { Box, Text } from 'theme-ui'
import { Application } from '../../../types/partTypes'

interface PartApplicationTableProps {
  applications: Application[]
}

export const PartApplicationTable = ({
  applications,
}: PartApplicationTableProps) => {
  let lastType = ''

  if (!applications?.length) return null

  return (
    <Box
      as="dl"
      sx={{
        display: 'grid',
        gridTemplateColumns: ['auto auto auto', null, '2fr 1fr 1fr'],
        columnGap: 2,
      }}
    >
      {applications.map(({ make, model, type }) => {
        const hideTypeOnMobile = lastType === type ? ['none', 'none', 'block'] : []

        lastType = type

        return (
          <Box
            key={`${make}-${model}-${type}`}
            sx={{
              gridColumn: '1 / -1',
              borderBlockEnd: '1px solid',
              borderColor: 'gray.1',
              display: 'grid',
              gridTemplateColumns: 'subgrid',
              paddingBlock: 2,
            }}
          >
            <div>
              <Text
                variant="bodyContent"
                sx={{
                  fontWeight: 'bold',
                  display: hideTypeOnMobile,
                }}
                color="gray.3"
              >
                {type}
              </Text>
            </div>

            <Text as="span" variant="bodyContent">
              {make}
            </Text>
            <Text
              as="span"
              variant="bodyContent"
              sx={{
                textAlign: ['right', 'right', 'left'],
              }}
            >
              {model}
            </Text>
          </Box>
        )
      })}
    </Box>
  )
}

export default PartApplicationTable
