import React from 'react'
import TableColumnTitle from '../../../../molecules/Table/TableColumnTitle'
import i18next from 'i18next'
import { Box, Text } from 'theme-ui'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../reducers'
import { useShoppingBasket } from '../../../../../providers/ShoppingBasketProvider'
import { CommerceOrderItem } from '../../../../../types/commerceApi'
import { getEditableTextFieldValue } from '../../../../../helpers/layoutServiceHelper'
import PartListItem from './PartListItem'
import PartListSalesInformationProvider from '../../../../../providers/PartListSalesInformationProvider'
import TableGridHead from '../../../../molecules/Table/TableGridHead'
import TableGridBody from '../../../../molecules/Table/TableGridBody'

interface PartListTableProps {
  measureClick: (part: CommerceOrderItem, index: number) => void
  getHref: (part: CommerceOrderItem) => string
}

const PartListTable = ({ measureClick, getHref }: PartListTableProps) => {
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const { datasource } = useShoppingBasket()

  return (
    <Box
      as="table"
      sx={{
        display: 'grid',
        gridTemplateColumns: '2.5fr 1fr 1fr 1fr 1.25fr 1fr 1fr',
        borderInline: '1px solid',
        borderColor: 'gray.1',
      }}
    >
      <TableGridHead hasBorderInline>
        <TableColumnTitle>{i18next.t('commonLabels.product')}</TableColumnTitle>

        <TableColumnTitle>{i18next.t('commonLabels.brand')}</TableColumnTitle>

        <TableColumnTitle>{i18next.t('stockLabels.availability')}</TableColumnTitle>

        <TableColumnTitle>{i18next.t('partSalesLabels.unitPrice')}</TableColumnTitle>

        <TableColumnTitle>{i18next.t('commonLabels.quantity')}</TableColumnTitle>

        <TableColumnTitle textAlign="end">
          {i18next.t('partSalesLabels.totalPrice')}
        </TableColumnTitle>
      </TableGridHead>

      {order?.items?.length ? (
        <PartListSalesInformationProvider
          includePrice
          partNumbersQuantity={order.items.map((item) => ({
            partNumber: item.partNumber,
            quantity: 1,
          }))}
        >
          <TableGridBody>
            {order.items.map((part, index) => (
              <PartListItem
                href={getHref(part)}
                part={part}
                key={part.partNumber}
                index={index}
                onClick={() => measureClick(part, index)}
              />
            ))}
          </TableGridBody>
        </PartListSalesInformationProvider>
      ) : (
        <TableGridBody>
          <Box
            as="tr"
            sx={{
              gridColumn: '1 / -1',
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
                padding: 4,
                borderBlockEnd: '1px solid',
                borderColor: 'gray.1',
              }}
            >
              <Text
                as="h2"
                variant="heading3"
                sx={{
                  marginBlockEnd: 2,
                }}
              >
                {i18next.t('shoppingLabels.noProducts')}
              </Text>

              <Text
                as="p"
                variant="bodySmall"
                sx={{
                  margin: 'auto',
                  maxWidth: '85ch',
                }}
              >
                {getEditableTextFieldValue(datasource.emptyOverviewMessage)}
              </Text>
            </Box>
          </Box>
        </TableGridBody>
      )}
    </Box>
  )
}

export default PartListTable
