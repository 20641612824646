import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCommerceApiPostService from '../../../../../../hooks/services/rest/ecommerce/useCommerceApiPostService'
import useCommerceApiResponse from '../../../../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import { ActiveUserStateContext } from '../../../../../../providers/ActiveUserStateProvider'
import { RootState } from '../../../../../../reducers'
import {
  OrderRecipientsRequestProps,
  postOrderRecipients,
} from '../../../../../../services/rest/ecommerce/order/order-recipients'
import {
  CommerceMessage,
  CommerceRecipient,
  CommerceRecipientEmailField,
  CommerceResponse,
} from '../../../../../../types/commerceApi'
import FormControl from '../../../../../atoms/FormControls/FormControl'
import FormFieldText from '../../../../../atoms/FormControls/FormFieldText'
import FormInputLabel from '../../../../../atoms/FormControls/FormInputLabel'
import FormInputTextList from '../../../../../atoms/FormControls/FormInputTextList/FormInputTextList'
import OrderRecipient from './OrderRecipient'
import { MutationType } from './OrderRecipients'

interface OrderFieldRecipientsProps {
  label: string
  emailField: CommerceRecipientEmailField
  disabled?: boolean
  readOnly?: boolean
  onChange: () => void
  dataTId?: string
  onChangeEnd: (
    mutationValue?: CommerceRecipient,
    mutationType?: MutationType
  ) => void
}

const OrderFieldRecipients: FC<OrderFieldRecipientsProps> = ({
  label,
  emailField,
  disabled,
  readOnly = false,
  onChange: onChangeCallback,
  onChangeEnd,
  dataTId,
}) => {
  const dispatch = useDispatch()
  const { orderNumber } = useContext(ActiveUserStateContext)
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)

  const [error, setError] = useState<string | null>(null)

  const recipients = useMemo(
    () =>
      order?.recipients?.filter(
        (recipient) => recipient.emailField === emailField
      ) || [],
    [order, emailField]
  )

  const [, response, executePostOrderRecipients] = useCommerceApiPostService<
    OrderRecipientsRequestProps,
    CommerceResponse<CommerceRecipient>
  >(postOrderRecipients)

  const onChange = useCallback(() => {
    return onChangeCallback?.()
  }, [dispatch, onChangeCallback])

  const onOrderRecipientInputChange = useCallback(
    (inputValue: string) => {
      executePostOrderRecipients({
        orderNumber,
        recipient: inputValue,
      })

      setError(null)

      return onChange()
    },
    [executePostOrderRecipients, onChange, orderNumber]
  )

  const onResult = useCallback(
    (recipient: CommerceRecipient | null) => {
      if (recipient) onChangeEnd(recipient, 'add')
    },
    [onChangeEnd]
  )

  const onMessage = useCallback(
    (commerceMessage: CommerceMessage) => {
      if (commerceMessage.message && commerceMessage.severity === 'Error') {
        onChangeEnd()
        setError(commerceMessage.message)
      }
    },
    [onChangeEnd]
  )

  useCommerceApiResponse<CommerceRecipient>({
    response,
    onResult,
    onMessage,
    messageSelector: (messages) => messages?.[0],
    resultSelector: (result) => result as CommerceRecipient,
  })

  return (
    <FormControl error={!!error} sx={{ gridColumn: '1 / 3' }} disabled={disabled}>
      <FormInputLabel>{label}</FormInputLabel>

      <FormInputTextList
        onChange={onOrderRecipientInputChange}
        type="email"
        dataTId={dataTId}
        readOnly={readOnly}
      >
        {recipients.map((recipient) => (
          <OrderRecipient
            dataTId={dataTId}
            key={recipient.emailAddress}
            recipient={recipient}
            onChange={onChange}
            onChangeEnd={onChangeEnd}
            disabled={disabled}
            readOnly={readOnly}
            fieldType={emailField}
          />
        ))}
      </FormInputTextList>

      <FormFieldText type="error">{error}</FormFieldText>
    </FormControl>
  )
}

export default OrderFieldRecipients
