import React, { FC, Fragment, useMemo } from 'react'
import { Box, Text } from 'theme-ui'
import { getPartAttributesWithPackagingQuantity } from '../../../helpers/graphPartHelper'
import { CommerceSpecification } from '../../../types/commerceApi'
import {
  CLASS_PROPERTY_TYPE_BOOLEAN_FALSE,
  CLASS_PROPERTY_TYPE_BOOLEAN_TRUE,
} from '../../../constants/searchConstants'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { ICONS } from '../../../constants/iconConstants'

interface PartSpecificationsTableProps {
  partAttributes?: CommerceSpecification['partAttributes']
  packaging?: CommerceSpecification['packaging']
}

export const PartSpecificationsTable: FC<PartSpecificationsTableProps> = ({
  partAttributes,
  packaging,
}) => {
  const sortedPartAttributes = useMemo(
    () =>
      partAttributes && partAttributes.length
        ? partAttributes.sort((a, b) => a.priority - b.priority)
        : [],
    [partAttributes]
  )

  const attributes = useMemo(
    () => getPartAttributesWithPackagingQuantity(sortedPartAttributes, packaging),
    [sortedPartAttributes, packaging]
  )

  if (!attributes?.length) return null

  return (
    <Box
      as="dl"
      sx={{
        display: 'grid',
        gridTemplateColumns: ['auto auto', null, '1fr 1fr'],
        columnGap: 2,
      }}
    >
      {attributes.map(({ description, value }) => {
        const displayIcon =
          value === CLASS_PROPERTY_TYPE_BOOLEAN_TRUE ||
          value === CLASS_PROPERTY_TYPE_BOOLEAN_FALSE
        const icon =
          ICONS[value === CLASS_PROPERTY_TYPE_BOOLEAN_TRUE ? 'CHECK' : 'CROSS']
        const iconStyles =
          value === CLASS_PROPERTY_TYPE_BOOLEAN_TRUE
            ? { marginLeft: '-9px' }
            : { marginLeft: '-6px' }

        return (
          <Fragment key={`${description}-${value}`}>
            <Box
              sx={{
                gridColumn: '1 / -1',
                borderBlockEnd: '1px solid',
                borderColor: 'gray.1',
                display: 'grid',
                gridTemplateColumns: 'subgrid',
                paddingBlock: 2,
              }}
            >
              <Text
                as="dt"
                variant="bodyContent"
                color="gray.3"
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {description}
              </Text>

              <Box
                as="dd"
                sx={{
                  textAlign: ['end', null, 'start'],
                }}
              >
                {displayIcon && (
                  <IconWrapper
                    style={iconStyles}
                    icon={icon}
                    color="text"
                    size={value === CLASS_PROPERTY_TYPE_BOOLEAN_TRUE ? 5 : 4}
                  />
                )}

                {!displayIcon && (
                  <Text as="span" variant="bodyContent">
                    {value}
                  </Text>
                )}
              </Box>
            </Box>
          </Fragment>
        )
      })}
    </Box>
  )
}

export default PartSpecificationsTable
