import React, { useMemo } from 'react'
import { Box, Text } from 'theme-ui'
import { ICONS } from '../../../constants/iconConstants'
import { COLORS } from '../../../constants/themeConstants'
import { Dealer } from '../../../types/dealerServiceTypes'
import { joinStringsWithDelimiter } from '../../../helpers/stringHelper'
import Spinner from '../../atoms/Spinner/Spinner'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'

interface ServiceDetailMainServiceDealerProps {
  serviceDealer: Dealer | undefined
  isFetching: boolean
}

const ServiceDetailMainServiceDealer = ({
  serviceDealer,
  isFetching,
}: ServiceDetailMainServiceDealerProps) => {
  const addressSegments = useMemo(
    () => [
      joinStringsWithDelimiter(
        [
          serviceDealer?.address?.addressLine1,
          serviceDealer?.address?.addressLine2,
          serviceDealer?.address?.addressLine3,
        ],
        ', '
      ),
      joinStringsWithDelimiter([
        serviceDealer?.address?.postalCode,
        serviceDealer?.address?.city,
      ]),
    ],
    [serviceDealer]
  )

  return (
    <>
      {!serviceDealer && !isFetching && (
        <Box>
          <Text variant="bodySmall" color={COLORS.GRAY}>
            -
          </Text>
        </Box>
      )}

      {!serviceDealer && isFetching && (
        <Box
          sx={{
            paddingY: 5,
            width: '100%',
            height: '100%',
          }}
        >
          <Spinner />
        </Box>
      )}

      {serviceDealer && !isFetching && (
        <>
          <Box
            sx={{
              display: 'grid',
              columnGap: 2,
              gridTemplateColumns: 'auto 1fr',
            }}
          >
            <IconWrapper icon={ICONS.TRUCK} color={COLORS.GRAY} />
            <Text
              variant="bodySmall"
              color={COLORS.GRAY}
              sx={{ paddingBlockStart: 1 }}
            >
              {serviceDealer?.name}
            </Text>

            <IconWrapper icon={ICONS.MARKER} color={COLORS.GRAY} />
            <Text
              variant="bodySmall"
              color={COLORS.GRAY}
              sx={{ paddingBlockStart: 1 }}
            >
              {`${
                addressSegments && !!addressSegments[0].length && addressSegments[0]
              }, ${
                addressSegments && !!addressSegments[1].length && addressSegments[1]
              }`}
            </Text>

            <IconWrapper icon={ICONS.PHONE} color={COLORS.GRAY} />
            <Text
              variant="bodySmall"
              color={COLORS.GRAY}
              sx={{ paddingBlockStart: 1 }}
            >
              {serviceDealer?.communications?.[0]?.phone}
            </Text>

            <IconWrapper icon={ICONS.ENVELOPE} color={COLORS.GRAY} />
            <Text
              variant="bodySmall"
              color={COLORS.GRAY}
              sx={{ paddingBlockStart: 1 }}
            >
              {serviceDealer?.communications?.[0]?.email}
            </Text>
          </Box>
        </>
      )}
    </>
  )
}

export default ServiceDetailMainServiceDealer
