import React, { FC, useCallback, useContext, useMemo } from 'react'
import { Box, Text } from 'theme-ui'
import { DATA_LAYER } from '../../../../../constants/dataLayerConstants'
import { ICONS } from '../../../../../constants/iconConstants'
import {
  CLASS_PROPERTY_TYPE_BOOLEAN,
  CLASS_PROPERTY_TYPE_BOOLEAN_TRUE,
} from '../../../../../constants/searchConstants'
import { measurePartClick } from '../../../../../helpers/analyticsHelper'
import { getGroupedPartAttributes } from '../../../../../helpers/searchResultsHelper'
import { loggedIn } from '../../../../../helpers/userHelper'
import { PartFulFilled } from '../../../../../hooks/services/rest/ecommerce/useFulfilledPartSearchService'
import { CategoriesContext } from '../../../../../providers/CategoriesProvider'
import { UserContext } from '../../../../../providers/UserProvider'
import { Property } from '../../../../../hooks/services/graphql/useClassInformationListService'
import { Item, LinkField, TextField } from '../../../../../types/layoutService'
import IconWrapper from '../../../../atoms/IconWrapper/IconWrapper'
import PartSalesInformation from '../../../../molecules/SalesInformation/PartSalesInformation'
import Supersession from '../../../../molecules/Supersession/Supersession'
import Image from '../../../../atoms/Image/Image'
import PartLineProductImage from '../../../../molecules/PartLine/PartLineProductImage'
import PartLineStockInformation from '../../../../molecules/PartLine/PartLineStockInformation'
import PartSalesInformationProvider from '../../../../../providers/PartSalesInformationProvider'
import PartLineAnchor from '../../../../molecules/PartLine/PartLineAnchor'

interface PartSearchTableRowProps {
  partFulfilled: PartFulFilled
  displayProperties: Property[]
  partPage: LinkField<Item>
  position: number
  supersessionText: TextField
}

const PartSearchTableRow: FC<PartSearchTableRowProps> = ({
  partFulfilled: { part, searchPart, uri },
  displayProperties,
  partPage,
  position,
  supersessionText,
}) => {
  const { user } = useContext(UserContext)
  const { categories } = useContext(CategoriesContext)

  const href = `${partPage.url}/${uri}`
  const groupedAttributes = useMemo(
    () =>
      getGroupedPartAttributes(
        part?.specification?.partAttributes?.filter(({ id }) =>
          displayProperties.find((property) => property.id === id)
        )
      ),
    [displayProperties, part]
  )

  const onClick = useCallback(
    () =>
      measurePartClick(
        part?.partNumber,
        part?.englishDescription,
        part?.brand?.description,
        part?.category?.mainCategoryId,
        part?.category?.subCategoryId,
        position,
        categories,
        DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_TABLE
      ),
    [categories, part, position]
  )

  return (
    <PartSalesInformationProvider partNumber={searchPart.partNumber}>
      <Box
        as="tr"
        sx={{
          gridColumn: '1 / -1',
          display: 'grid',
          gridTemplateColumns: 'subgrid',
          alignItems: 'center',
          paddingBlock: 3,
          borderBlockEnd: '1px solid',
          borderColor: 'gray.1',
          '> *': {
            gridRow: '1 / 2',
          },
        }}
      >
        <Box
          as="td"
          sx={{
            gridColumn: '1 / 2',
          }}
        >
          <PartLineProductImage
            partNumber={searchPart.partNumber}
            src={part?.images?.[0].thumbnailImageUrl}
            alt={part?.description}
            sx={{
              maxWidth: 48,
              maxHeight: 48,
            }}
          />
        </Box>

        <Box
          as="td"
          sx={{
            // from 1st to 2nd, 3rd, x and 'brand' column, which makes 5
            gridColumn: `1 / ${5 + displayProperties.length}`,
            display: 'grid',
            gridTemplateColumns: 'subgrid',
            position: 'relative',
          }}
        >
          <div></div>
          <PartLineAnchor
            href={href}
            onClick={onClick}
            sx={{
              '&:hover, &:active, &:focus': {
                textDecoration: 'underline',
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                insetInline: 0,
                insetBlock: '-17px',
              },
            }}
          >
            <Text
              variant="bodySmall"
              sx={{
                display: 'flex',
                alignItems: 'center',
                lineHeight: 'menuList',
              }}
            >
              {part?.displayPartNumber || searchPart.partNumber}

              <Supersession
                partDescription={part?.description || ''}
                partNumber={searchPart.partNumber}
                matchMetaData={searchPart.matchMetaData}
                sidePanelText={supersessionText}
              />
            </Text>
          </PartLineAnchor>
        </Box>

        <Box
          as="td"
          sx={{
            gridColumn: '3 / 4',
          }}
        >
          <Text
            as="span"
            variant="bodySmall"
            color="gray.2"
            sx={{
              lineHeight: 'menuList',
            }}
          >
            {part?.supplierPartNumber && part.supplierPartNumber}
          </Text>
        </Box>

        {displayProperties &&
          groupedAttributes &&
          displayProperties.map(({ id, type }, index) => (
            <Box
              as="td"
              key={id}
              sx={{
                gridColumn: `${4 + index} / ${5 + index}`,
              }}
            >
              {groupedAttributes?.[id] &&
                groupedAttributes[id].map(({ value }) => (
                  <Text
                    key={value}
                    variant="bodySmall"
                    color="gray.2"
                    sx={{
                      lineHeight: 'menuList',
                    }}
                  >
                    {type === CLASS_PROPERTY_TYPE_BOOLEAN ? (
                      <>
                        <IconWrapper
                          style={
                            value === CLASS_PROPERTY_TYPE_BOOLEAN_TRUE
                              ? {}
                              : { marginLeft: '4px' }
                          }
                          icon={
                            ICONS[
                              value === CLASS_PROPERTY_TYPE_BOOLEAN_TRUE
                                ? 'CHECK'
                                : 'CROSS'
                            ]
                          }
                          color="text"
                          size={value === CLASS_PROPERTY_TYPE_BOOLEAN_TRUE ? 5 : 4}
                        />
                      </>
                    ) : (
                      <>{value}</>
                    )}
                  </Text>
                ))}
            </Box>
          ))}

        <Box
          as="td"
          sx={{
            gridColumn: '8 / 9',
          }}
        >
          {part?.brand && (
            <Image
              src={part.brand?.image?.thumbnailImageUrl}
              alt={part.brand.description}
              title={part.brand.description}
              sx={{
                width: 'auto',
                height: 24,
                objectFit: 'contain',
              }}
            />
          )}
        </Box>

        {loggedIn(user) && (
          <td>
            <PartLineStockInformation
              partNumber={searchPart.partNumber}
              displayPartNumber={part?.displayPartNumber}
              componentOrigin={DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_TABLE}
              categoryId={part?.category?.mainCategoryId}
              subcategoryId={part?.category?.subCategoryId}
            />
          </td>
        )}

        {loggedIn(user) && (
          <td>
            <PartSalesInformation
              partNumber={searchPart.partNumber}
              partEnglishDescription={part?.englishDescription}
              brandName={part?.brand?.description}
              categoryId={part?.category?.mainCategoryId}
              subcategoryId={part?.category?.subCategoryId}
              dataLayerComponentOrigin={
                DATA_LAYER.COMPONENT_ORIGIN.SEARCH_RESULT_TABLE
              }
              packageUnitQuantity={
                part?.specification?.packaging?.packageUnitQuantity
              }
            />
          </td>
        )}
      </Box>
    </PartSalesInformationProvider>
  )
}

export default PartSearchTableRow
