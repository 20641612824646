import React, { FC, FormEvent, useCallback, useMemo, useState } from 'react'
import { Box, Text } from 'theme-ui'
import { DATA_LAYER } from '../../../constants/dataLayerConstants'
import { pushToDataLayer } from '../../../helpers/analyticsHelper'
import {
  Datasource,
  LinkField,
  Params,
  TextField,
} from '../../../types/layoutService'
import SearchBarInput from '../SearchBar/SearchBarInput'
import {
  URL_VEHICLE_SEARCH_QUERY_PARAM,
  URL_WILDCARD_PLACEHOLDER,
} from '../../../constants/urlConstants'
import SearchBarForm from '../SearchBar/SearchBarForm'
import { ICONS } from '../../../constants/iconConstants'
import { resolveLocalizedUrl } from '../../../helpers/linkFieldHelper'
import { COLORS } from '../../../constants/themeConstants'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { navigateClient } from '../../../helpers/dom'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import Button from '../../atoms/Button/Button'
import withData from '../../../enhancers/withData'
import useDafVehicleReferenceService from '../../../hooks/services/rest/ecommerce/useDafVehicleReferenceService'
import { VehicleReference } from '../../../services/rest/ecommerce/dafVehicleReferences'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'

interface VehicleSearchDatasource {
  vehicleSearchPage: LinkField
  vehicleSearchLabel: TextField
  vehicleSearchPlaceholder: TextField
  vehicleSearchSubmitButtonLabelShort: TextField
  vehicleSearchSubmitButtonLabelLong: TextField
}

interface VehicleSearchRenderingParams {
  visualStyle?: StyleVariants
}

enum StyleVariants {
  Regular = 'Regular',
  HeroBanner = 'Hero Banner',
}

const getReferenceOptionLabel = (reference: VehicleReference) =>
  Object.values(reference)
    .map((value) => value)
    .filter((value) => value)
    .join(' | ')

const VehicleSearch: FC<
  Datasource<VehicleSearchDatasource> & Params<VehicleSearchRenderingParams>
> = ({ datasource, params }) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const [query, setQuery] = useState('')
  const trimmedSearchString = useMemo(() => query.trim(), [query])
  const [isFetching, dafVehicleReferences] = useDafVehicleReferenceService()

  const getSearchDestinationUrl = (): string => {
    const isWildcardUrl = datasource?.vehicleSearchPage?.url.includes(
      URL_WILDCARD_PLACEHOLDER
    )

    if (isWildcardUrl) {
      return datasource.vehicleSearchPage.url.replace(
        URL_WILDCARD_PLACEHOLDER,
        trimmedSearchString
      )
    }

    return `${resolveLocalizedUrl(
      datasource?.vehicleSearchPage,
      cultureCode
    )}?${URL_VEHICLE_SEARCH_QUERY_PARAM}=${trimmedSearchString}`
  }

  const submitSearch = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      pushToDataLayer({
        [DATA_LAYER.EVENT_KEYS.EVENT]: DATA_LAYER.EVENT_NAME.SEARCH_FUNCTION,
        search_type: DATA_LAYER.CUSTOM_DIMENSION.SEARCH_TYPE.VEHICLE_SEARCH,
        search_query: trimmedSearchString,
        page_type: '',
      })

      navigateClient(getSearchDestinationUrl())
    },
    [cultureCode, trimmedSearchString, datasource]
  )

  return (
    <>
      {datasource?.vehicleSearchLabel?.value && (
        <label htmlFor="banner-call-to-action-search-input">
          <Text
            as="label"
            variant={
              params.visualStyle === StyleVariants.HeroBanner
                ? 'heading5'
                : 'heading2'
            }
            sx={{
              display: 'block',
              marginBlockEnd:
                params.visualStyle === StyleVariants.HeroBanner ? 2 : 5,
              color:
                params.visualStyle === StyleVariants.HeroBanner
                  ? 'textLight'
                  : 'dark',
            }}
          >
            {getTextFieldValue(datasource.vehicleSearchLabel)}
          </Text>
        </label>
      )}

      <SearchBarForm
        onSubmit={submitSearch}
        sx={{
          width: ['100%', '100%', '100%', '50%'],
          display: 'flex',
          alignItems: 'center',
          paddingBlock: 1,
          paddingInline: 1,
          backgroundColor: 'gray.0',
          outline:
            params.visualStyle === StyleVariants.HeroBanner
              ? `3px solid color-mix(in srgb, ${COLORS.BLACK} 24%, transparent)`
              : 'none',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            insetInlineStart: 2,
            pointerEvents: 'none',
          }}
        >
          <IconWrapper icon={ICONS.SEARCH} />
        </Box>
        <SearchBarInput
          id="banner-call-to-action-search-input"
          list="chassisNumbers"
          name={URL_VEHICLE_SEARCH_QUERY_PARAM}
          placeholder={getTextFieldValue(datasource?.vehicleSearchPlaceholder)}
          onChange={(ev) => setQuery(ev.currentTarget.value)}
          sx={{
            '::placeholder': {
              color:
                params.visualStyle === StyleVariants.HeroBanner ? 'gray.2' : 'dark',
            },
          }}
        />

        <datalist id="chassisNumbers">
          {!isFetching &&
            dafVehicleReferences?.length &&
            dafVehicleReferences.map((reference) => (
              <option value={reference.chassisNumber} key={reference.chassisNumber}>
                {getReferenceOptionLabel(reference)}
              </option>
            ))}
        </datalist>

        <Box
          sx={{
            flexShrink: 0,
          }}
        >
          <Button type="submit">
            <Box
              sx={{
                display: ['block', 'none'],
              }}
            >
              {getTextFieldValue(datasource?.vehicleSearchSubmitButtonLabelShort)}
            </Box>
            <Box
              sx={{
                display: ['none', 'block'],
              }}
            >
              {getTextFieldValue(datasource?.vehicleSearchSubmitButtonLabelLong)}
            </Box>
          </Button>
        </Box>
      </SearchBarForm>
    </>
  )
}

export default withData(VehicleSearch)
