import React, { useContext, useState } from 'react'
import { Box, Text } from 'theme-ui'
import withData from '../../../enhancers/withData'
import { Datasource, TextField } from '../../../types/layoutService'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../types/userProps'
import useAxiosClient from '../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../providers/AxiosClientProvider'
import { UserContext } from '../../../providers/UserProvider'
import getVouchers from '../../../services/rest/ecommerce/loyalty/vouchers'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import Pagination from '../Pagination/Pagination'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { SORT_DIRECTION_DESCENDING } from '../../../constants/searchConstants'
import Spinner from '../../atoms/Spinner/Spinner'
import VoucherCodesOverviewTable from './VoucherCodesOverviewTable'
import VoucherCodesOverviewCard from './VoucherCodesOverviewCard'
import { QUERY_KEY_VOUCHERS } from '../../../constants/queryKeyConstants'

export interface VoucherCodesOverviewDatasource {
  voucherCodesOverviewTitle: TextField
  noResultsTitle: TextField
  noResultsDescription: TextField
  voucher: TextField
  points: TextField
  issued: TextField
  code: TextField
  expires: TextField
  status: TextField
}

const VoucherCodesOverview = ({
  datasource,
}: Datasource<VoucherCodesOverviewDatasource>) => {
  const [page, setPage] = useState(0)
  const partsPerPage = 5

  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const queryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const getVouchersData = async () => {
    const { token } = axios.CancelToken.source()
    const response = await getVouchers(
      {
        cultureCode: cultureCode || '',
        paging: {
          pageNumber: page + 1,
          partsPerPage: partsPerPage,
        },
        sorting: {
          sortField: SORT_DIRECTION_DESCENDING,
          sortOrder: SORT_DIRECTION_DESCENDING,
        },
      },
      queryParams,
      client,
      token,
      user
    )
    return response.data
  }

  const { isPending, data } = useQuery({
    queryKey: [QUERY_KEY_VOUCHERS, page],
    queryFn: () => getVouchersData(),
  })

  const voucherData = data?.result?.vouchers
  const totalVoucherCount = data?.result?.statistics?.totalCount || 0

  return (
    <>
      <Box>
        <Text
          as="h2"
          variant="heading2"
          color="text"
          sx={{ marginBlockEnd: [2, 2, 5] }}
        >
          {getTextFieldValue(datasource.voucherCodesOverviewTitle)}
        </Text>
      </Box>
      <Box>
        {isPending ? (
          <Box>
            <Spinner />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: ['none', null, 'block'],
              }}
            >
              <VoucherCodesOverviewTable
                vouchers={voucherData ?? []}
                datasource={datasource}
              />
            </Box>
            <Box
              sx={{
                display: ['block', null, 'none'],
              }}
            >
              <VoucherCodesOverviewCard
                vouchers={voucherData ?? []}
                datasource={datasource}
              />
            </Box>
          </>
        )}

        {voucherData && voucherData?.length > 0 ? (
          <Box sx={{ py: 4 }}>
            <Pagination
              onNavigate={(newPageIndex) => {
                setPage(newPageIndex)
              }}
              totalCount={Math.ceil(totalVoucherCount / partsPerPage)}
              previousIndex={page === 0 ? null : page - 1}
              currentIndex={page}
              nextIndex={
                page === Math.ceil(totalVoucherCount / partsPerPage) - 1
                  ? null
                  : page + 1
              }
            />
          </Box>
        ) : null}
      </Box>
    </>
  )
}

export default withData(VoucherCodesOverview)
