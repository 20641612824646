import React, { useMemo } from 'react'
import { Box, Text } from 'theme-ui'
import TableRow from '../../molecules/Table/TableRow'
import TableColumnTitle from '../../molecules/Table/TableColumnTitle'
import TableGridHead from '../../molecules/Table/TableGridHead'
import TableGridBody from '../../molecules/Table/TableGridBody'

export interface PartQuantumPriceTableProps {
  columns: PartQuantumPriceTableColumnProps[]
  rows: PartQuantumPriceTableRowProps[]
}

export interface PartQuantumPriceTableColumnProps {
  id: string
  label?: string
}

export interface PartQuantumPriceTableRowProps {
  label: string
  quantity?: string
  stockPrice?: string
  rushPrice?: string
  endDate?: string
  priceType?: string
  reference?: string
}

const PartQuantumPriceTable = ({ rows, columns }: PartQuantumPriceTableProps) => {
  const columnSizes = useMemo(
    () => (columns?.length ? columns.map((_, i) => (i === 0 ? 2 : 1)) : []),
    [columns]
  )

  if (!columns?.length || !rows?.length) return null

  return (
    <Box
      as="table"
      sx={{
        display: 'grid',
        gridTemplateColumns: `${columnSizes.join('fr ')}fr`,
        borderColor: 'gray.1',
      }}
    >
      <TableGridHead>
        {columns.map((column) => (
          <TableColumnTitle key={column.id}>
            {column.label ? column.label : null}
          </TableColumnTitle>
        ))}
      </TableGridHead>

      <TableGridBody>
        {rows.map((row) => (
          <TableRow
            key={row.label}
            sx={{
              paddingBlock: 2,
            }}
          >
            <Box
              as="th"
              sx={{
                textAlign: 'start',
              }}
            >
              {row.label && (
                <Text
                  as="strong"
                  variant="bodyContent"
                  color="gray.3"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {row.label}
                </Text>
              )}
            </Box>

            <td>
              {row.quantity && (
                <Text as="span" variant="bodyContent">
                  {row.quantity}
                </Text>
              )}
            </td>

            <td>
              {row.stockPrice && (
                <Text as="span" variant="bodyContent">
                  {row.stockPrice}
                </Text>
              )}
            </td>

            <td>
              {row.rushPrice && (
                <Text as="span" variant="bodyContent">
                  {row.rushPrice}
                </Text>
              )}
            </td>

            <td>
              {row.endDate && (
                <Text as="span" variant="bodyContent">
                  {row.endDate}
                </Text>
              )}
            </td>

            <td>
              {row.priceType && (
                <Text as="span" variant="bodyContent">
                  {row.priceType}
                </Text>
              )}
            </td>

            <td>
              {row.reference && (
                <Text as="span" variant="bodyContent">
                  {row.reference}
                </Text>
              )}
            </td>
          </TableRow>
        ))}
      </TableGridBody>
    </Box>
  )
}

export default PartQuantumPriceTable
