import React from 'react'
import { Box, Text } from 'theme-ui'
import { DealerPartCrossReference } from '../../../hooks/services/graphql/useDealerPartListService'

interface DealerPartCrossReferencesTableProps {
  crossReferences: DealerPartCrossReference[]
}

export const DealerPartCrossReferencesTable = ({
  crossReferences,
}: DealerPartCrossReferencesTableProps) => {
  let lastVendorName = ''

  if (!crossReferences?.length) return null

  return (
    <Box
      as="dl"
      sx={{
        display: 'grid',
        gridTemplateColumns: ['auto auto auto', null, '2fr 1fr 1fr'],
        columnGap: 2,
      }}
    >
      {crossReferences.map(({ vendorName, crossReferencePartNumber, brandName }) => {
        const hideVendorNameOnMobile =
          lastVendorName === vendorName ? ['none', 'none', 'block'] : []

        lastVendorName = vendorName

        return (
          <Box
            key={`${vendorName}-${crossReferencePartNumber}-${brandName}`}
            sx={{
              gridColumn: '1 / -1',
              borderBlockEnd: '1px solid',
              borderColor: 'gray.1',
              display: 'grid',
              gridTemplateColumns: 'subgrid',
              paddingBlock: 2,
            }}
          >
            <Text
              as="strong"
              variant="bodyContent"
              color="gray.3"
              sx={{
                display: hideVendorNameOnMobile,
                fontWeight: 'bold',
              }}
            >
              {vendorName}
            </Text>
            <Text
              as="span"
              variant="bodyContent"
              sx={{
                textAlign: ['right', 'right', 'left'],
              }}
            >
              {brandName}
            </Text>
            <Text
              as="span"
              variant="bodyContent"
              sx={{
                textAlign: ['right', 'right', 'left'],
              }}
            >
              {crossReferencePartNumber}
            </Text>
          </Box>
        )
      })}
    </Box>
  )
}

export default DealerPartCrossReferencesTable
