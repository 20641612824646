import React from 'react'
import { Text } from 'theme-ui'

interface TableColumnTitleProps {
  children: React.ReactNode
  textAlign?: 'end' | 'center'
}

const TableColumnTitle = ({ children, textAlign }: TableColumnTitleProps) => (
  <th scope="column">
    <Text
      as="div"
      variant="smallText"
      sx={{
        textAlign: textAlign || 'start',
        fontWeight: 500,
      }}
    >
      {children}
    </Text>
  </th>
)

export default TableColumnTitle
